import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    cardHeader: {
        textAlign: "center",
        paddingBottom: "0px !important",
    },
}));

export default useStyles;