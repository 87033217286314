import React, { useEffect } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import SearchIcon from "@mui/icons-material/Search";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

import { Hidden, Paper } from "@mui/material";
import useStyles from "./style";
import { useLocalState } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { profileReq } from "../../../store/slices/profileSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { BottomNavSelector } from "./selector";

const payload = { limit: 12, page: 1, isInit: true, listName: "all" };
export default function BottomNav() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const [LS, setLS] = useLocalState({ selectedMenu: "all" });
  const RS = useSelector(BottomNavSelector)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setLS({ selectedMenu: newValue });
    dispatch(profileReq({ ...payload, listName: newValue }));
    if (location.pathname !== "/user") navigate("/user");
  };

  useEffect(() => {
    if (RS.profiles.length === 0) dispatch(profileReq(payload));
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname === "/user/profile") setLS({ selectedMenu: "" });
  }, [location.pathname]);

  return (
    <Paper className={classes.paper} elevation={3}>
      <BottomNavigation value={LS.selectedMenu} onChange={handleChange}>
        <BottomNavigationAction
          label="Search"
          value="search"
          icon={
            <SearchIcon
              className={LS.selectedMenu === "search" ? classes.selected : ""}
            />
          }
          sx={{ paddingLeft: "25px" }}
        />
        <BottomNavigationAction
          label="Viewers"
          value="viewers"
          icon={
            <PersonSearchIcon
              className={
                LS.selectedMenu === "viewers" ? classes.selected : ""
              }
            />
          }
        />
        <BottomNavigationAction
          label="All"
          value="all"
          icon={
            <HomeOutlinedIcon
              className={LS.selectedMenu === "all" ? classes.selected : ""}
            />
          }
          sx={{ paddingLeft: "25px" }}
        />
        <BottomNavigationAction
          label="Sent"
          value="sent"
          icon={
            <UnarchiveOutlinedIcon
              className={LS.selectedMenu === "sent" ? classes.selected : ""}
            />
          }
        />
        <BottomNavigationAction
          label="Received"
          value="received"
          icon={
            <ArchiveOutlinedIcon
              className={
                LS.selectedMenu === "received" ? classes.selected : ""
              }
            />
          }
          sx={{ paddingRight: "30px" }}
        />
      </BottomNavigation>
    </Paper>
  );
}
