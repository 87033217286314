
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import RestoreIcon from "@mui/icons-material/Restore";
import PersonIcon from '@mui/icons-material/Person';
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import SettingsIcon from "@mui/icons-material/Settings";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import BlockIcon from "@mui/icons-material/Block";
import SwipeLeftOutlinedIcon from "@mui/icons-material/SwipeLeftOutlined";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { UserProfileMenuSelector } from "./selector";


const menuList = [
  { id: 1, icon: RestoreIcon, text: "Recent", color: "yellow", route: 'recent-views', disabled: false },
  { id: 2, icon: PersonIcon, text: "Edit Profile", color: "#76ff03", route: 'edit-profile', disabled: false },
  { id: 3, icon: SettingsIcon, text: "Settings", color: "inherit", route: "settings", disabled: false },
  { id: 4, icon: BookmarkBorderIcon, text: "My Book", color: "gray", disabled: true },
  { id: 5, icon: SwipeLeftOutlinedIcon, text: "Rejected", color: "gray", disabled: true },
  { id: 6, icon: BlockIcon, text: "Blocked", color: "gray", disabled: true },
];

export default function UserProfileMenu({
  profileMenuAnchor,
  handleProfileMenuClose,
}: any) {
  const navigate = useNavigate();
  const RS = useSelector(UserProfileMenuSelector);

  const handleLogOut = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/";
  };

  const handleProfileMenuClick = (e: any, item: any) => {
    if (item.route) navigate(`/user/${item.route}`)
    handleProfileMenuClose(e);
  };

  return (
    <Menu
      anchorEl={profileMenuAnchor}
      open={Boolean(profileMenuAnchor)}
      onClose={handleProfileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuClose}>
        <Grid container>
          <Grid item sx={{ width: "100%" }}>
            <Avatar src={RS?.authUser?.profile?.img} sx={{ margin: "auto" }} />
          </Grid>
          <Grid item sx={{ width: "100%", textAlign: "center" }}>
            <Typography variant="body1">{RS?.authUser?.firstName}</Typography>
          </Grid>
        </Grid>
      </MenuItem>
      <Divider />
      <Container
        maxWidth="xs"
        sx={{ paddingTop: "10px", paddingBottom: "20px" }}
      >
        <Grid container spacing={2}>
          {menuList.map((item: any) => (
            <Grid key={item.id} item xs={4} sm={4} md={4} lg={4}>
              <Grid
                container
                onClick={(e) => item.disabled ? '' : handleProfileMenuClick(e, item)}
              >
                <Grid item sx={{ width: "100%", textAlign: "center" }}>
                  {<item.icon sx={{ fontSize: "32px", color: item.color }} />}
                </Grid>
                <Grid item sx={{ width: "100%", textAlign: "center" }}>
                  <Typography color={item.disabled ? 'gray' : 'inherit'} variant="body2">{item.text}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Divider />
      <MenuItem sx={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <IconButton color="warning" sx={{ marginLeft: '20px' }}>
              <CurrencyRupeeIcon />
            </IconButton>
            <Typography variant="body2">{RS?.authUser?.expiryIn} Days Left</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: "right" }}>
            <IconButton onClick={handleLogOut} sx={{ color: 'red', marginRight: '10px' }}>
              <PowerSettingsNewIcon />
            </IconButton>
            <Typography variant="body2">Sign Out</Typography>
          </Grid>
        </Grid>
      </MenuItem>
    </Menu>
  );
}
