import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDeviceDetails } from "../../store/slices/deviceSlice";
import { isMobile, osName } from "react-device-detect";

export default function Device() {
  const dispatch = useDispatch();
  useEffect(() => {
    const storeDeviceDetails = () => {
      const isAndroid = osName === "Android";
      const isIOS = osName === "iOS";
      const isWindows = osName === "Windows";
      const payload = {
        isMobile,
        isAndroid,
        isIOS,
        isWindows,
        platform: osName,
      };
      dispatch(setDeviceDetails(payload));
    };
    storeDeviceDetails();
    window.addEventListener("resize", storeDeviceDetails);
  }, [dispatch]);

  return <></>;
}
