import React from 'react';
import { Form, Formik } from 'formik'

import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SignInValidationSchema from '../../../pages/sign-in/validation'
import TextInput from '../../inputs/text-input';
import { initialValues, selectIsLoading } from '../../../pages/sign-in/selector'
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { signReq } from '../../../store/slices/authSlice';
import { useLocalState } from '../../../hooks';
import { IAction, SignInFormValues } from '../../../pages/sign-in/types';
import DynamicModal from '../../modal';
import SignInOtp from '../../sign-in-otp';

export default function SignInForm() {
  const dispatch = useDispatch();

  const [LS, setState] = useLocalState({ isModalOpen: false })
  const isLoading = useSelector(selectIsLoading)

  const handleSignIn = (values: SignInFormValues, actions: IAction) => {
    dispatch(signReq(values));
  };

  const handleOTPSignIn = () => {
    setState({ isModalOpen: true });
  };

  const onCloseModal = (event: MouseEvent, resion: string) => {
    // if(resion === 'backdropClick') return;
    setState({ isModalOpen: false });
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={SignInValidationSchema}
        onSubmit={handleSignIn}
      >
        {(formik) => (
          <Box component={Form} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput label="Mobile" name="username" formik={formik} />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} container>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      name="remember"
                      value="remember"
                      color="primary"
                    />
                  }
                  label="Remember me"
                /> */}
                <Grid item xs={6} sx={{pr:1, mb:2}}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={formik.isSubmitting && isLoading}
                  >
                    {formik.isSubmitting && isLoading ? "Submitting..." : "Sign In"}
                  </Button>
                </Grid>
                <Grid item xs={6} sx={{pl:1}}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    disabled={formik.isSubmitting && isLoading}
                    onClick={handleOTPSignIn}
                  >
                    OTP Login
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
              </Grid>
              <Grid item>
                <RouterLink to="/">
                  <Link component={"span"} variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
      <DynamicModal
        open={LS.isModalOpen}
        title="OTP"
        subheader=""
        content={<SignInOtp />}
        onClose={onCloseModal}
        size={"xs"}
      />
    </React.Fragment>

  )
}
