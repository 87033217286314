import { createSelector } from "@reduxjs/toolkit";

export const AuthUserSelector = (state: any) => state.auth.user;

export const ProfilesSelector = createSelector(
    (state: any) => state.profiles,
    (profiles) => profiles
);

export const StaticDataSelector = createSelector(
    (state: any) => state.staticData,
    (staticData) => staticData
);

export const ProfileCardSelector = createSelector(
    [AuthUserSelector],
    (authUser: any) => {
        return { authUser }
    }
)

export const PaymentPageSelector = createSelector(
    [AuthUserSelector],
    (authUser: any) => {
        return { authUser }
    }
)

export const HeaderSelector = createSelector(
    [AuthUserSelector, ProfilesSelector],
    (authUser, profiles) => {
        return { authUser, profiles }
    }
)