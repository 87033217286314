import { Card, CardContent, Typography, CardHeader, Box, makeStyles, Theme, Grid, Divider } from "@mui/material";
import CardWrapper from "../card-wrapper";
import React from "react";

const Details = ({ profileData }: any) => {
  const cards = [
    {
      title: 'Personal Details',
      data: [
        { label: 'Date Of Birth', value: profileData.dateOfBirth },
        { label: 'Birth Time', value: profileData.birthTime },
        { label: 'Birth Place', value: profileData.birthPlace },
        { label: 'Birth Order', value: profileData.birthOrder },
        { label: 'Physical Status', value: profileData.physicalStatus },
        { label: 'Economic Status', value: profileData.economicStatus },
        { label: 'Weight', value: profileData.weight ? `${profileData.weight} kg` : '-' },
        { label: 'Height', value: profileData.height ? `${profileData.height} inch` : '-' },
        { label: 'Body Type', value: profileData.bodyType },
      ]
    },
    {
      title: 'Professional Details',
      data: [
        { label: 'Profession', value: profileData.profession },
        { label: 'Qualification', value: profileData.qualification },
        { label: 'Salary', value: profileData.salary },
      ]
    },
    {
      title: 'Current Address Details',
      data: [
        { label: 'Country', value: profileData.country },
        { label: 'State', value: profileData.state },
        { label: 'District', value: profileData.city },
        { label: 'Address', value: profileData.address },
      ]
    },
    {
      title: 'Native Address Details',
      data: [
        { label: 'Country', value: profileData.nativeCountry },
        { label: 'State', value: profileData.nativeState },
        { label: 'District', value: profileData.nativeDistrict },
        { label: 'Address', value: profileData.nativeAddress },
      ]
    },
    {
      title: 'Astrology Details',
      data: [
        { label: 'Star', value: profileData.star },
        { label: 'Raasi', value: profileData.raasi },
        { label: 'Paadham', value: profileData.paadham },
        { label: 'Sevvai', value: profileData.sevvai },
        { label: 'Raagu Kethu', value: profileData.raghuKedhu },
        { label: 'Lagnam', value: profileData.lagnam },
      ]
    },
    {
      title: 'Family Details',
      data: [
        { label: 'Father Name', value: profileData.father },
        { label: 'Father`s Occupation', value: profileData.fatherOccupation },
        { label: 'Mother Name', value: profileData.mother },
        { label: 'Mother`s Occupation', value: profileData.motherOccupation },
        { label: 'No of sisters', value: profileData.noOfSisters },
        { label: 'No of sisters married', value: profileData.noOfSistersMarried },
        { label: 'No of Brothers', value: profileData.noOfBrothers },
        { label: 'No of Brothers married', value: profileData.noOfBrothersMarried },
        { label: 'Family Type', value: profileData.familyType },
      ]
    },
    {
      title: 'Community Details',
      data: [
        { label: 'Mother Toungue', value: profileData.religion },
        { label: 'Caste', value: profileData.caste },
        { label: 'Sub Caste', value: profileData.subCaste },
        { label: 'Sub Caste Additional', value: profileData.subCasteAD },
      ]
    },
    {
      title: 'Additional Details',
      data: [
        { label: 'Property Details', value: profileData.property },
      ]
    }
  ]
  return (
    <>
      <CardWrapper title={profileData.fullName}>
        <Typography color="textSecondary">
          {`${profileData.age} years, ${profileData.maritalStatus}, ${profileData.gender}`}
        </Typography>
      </CardWrapper>
      <CardWrapper title="About Me">
        <Typography color="textSecondary" textAlign="justify">
          {profileData.aboutYou}
        </Typography>
      </CardWrapper>
      {
        cards.map(({ title, data }: any, cardIndex: number) => {
          return <CardWrapper key={cardIndex} title={title}>
            {data.map(({ label, value }: any, index: number) => {
              return <React.Fragment key={index}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography>{label}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{value}</Typography>
                  </Grid>
                </Grid>
                <Divider />
              </React.Fragment>
            })}
          </CardWrapper>
        })
      }
    </>
  );
};

export default Details;
