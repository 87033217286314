import { Routes, Route } from "react-router-dom";
import SignIn from "../pages/sign-in";
import SignUp from "../pages/sign-up";
import Dashboard from "../pages/user/dashboard";
import Forget from "../pages/forget";
import Pricing from "../pages/user/pricing";
import User from "../pages/user";
import EditProfile from "../pages/user/edit-profile";
import ViewProfile from "../pages/user/view-profile";
import Settings from "../pages/user/settings";
import Payment from "../pages/user/payment";
import RecentViews from "../pages/user/recent-views";
import Contact from "../pages/contact";
import Admin from "../pages/admin";
import Terms from "../pages/terms & conditions";
import PrivacyPolicy from "../pages/privacy";

export default function Broutes() {
  return (
    <Routes>
      <Route path="" element={<SignUp />} />
      <Route path="sign-in" element={<SignIn />} />
      <Route path="forget" element={<Forget />} />
      <Route path="pricing" element={<Pricing />} />
      <Route path="contact" element={<Contact />} />
      <Route path="terms" element={<Terms />} />
      <Route path="privacy" element={<PrivacyPolicy />} />
      <Route path="user" element={<User />}>
        <Route path="" element={<Dashboard />} />
        <Route path="edit-profile" element={<EditProfile />} />
        <Route path="view-profile" element={<ViewProfile />} />
        <Route path="make-payment" element={<Payment />} />
        <Route path="recent-views" element={<RecentViews />} />
        <Route path="settings" element={<Settings />} />
      </Route>
      <Route path="admin" element={<Admin />}>

      </Route>
    </Routes>
  );
}
