import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Badge,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Hidden from "@mui/material/Hidden";
import { useSelector } from "react-redux";
import SideNav from "../mobile/side-nav";

import UserProfileMenu from "../user-profile-menu";
import UserNotification from "../user-notification";
import { Link } from "react-router-dom";
import { HeaderSelector } from "../../store/selector";
import Logo from "./logo.png";

const Header: React.FC = () => {
  const [elevation, setElevation] = useState(5);
  const RS = useSelector(HeaderSelector);
  const { authUser, profiles } = RS;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [profileMenuAnchor, setProfileMenuAnchor] =
    useState<null | HTMLElement>(null);
  const [notificationMenuAnchor, setNotificationMenuAnchor] =
    useState<null | HTMLElement>(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchor(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchor(null);
  };

  const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationMenuAnchor(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setNotificationMenuAnchor(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 5) {
        setElevation(8);
      } else {
        setElevation(5);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getListName = () => {
    if (profiles.listName === "sent") return "InterestSent";
    if (profiles.listName === "received") return "InterestReceived";
    if (profiles.listName === "viewers") return "WhoViewedMe";
    if (profiles.listName === "search") return "Search";
    if (profiles.listName === "all") return "Recents";
  };

  return (
    <>
      <AppBar position="fixed" elevation={elevation}>
        <Toolbar>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open mobile menu"
              edge="start"
              onClick={toggleMobileMenu}
              sx={{ display: "none" }}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <img src={Logo} height={65} />
            {/* <span style={{ fontSize: "12px" }}>
              ({getListName()} - {profiles.totalItems})
            </span> */}
          </Typography>
          <Hidden smDown mdDown>
            {/* <Link to="/user" style={{ color: "inherit" }}>
              <Button color="inherit">Home</Button>
            </Link> */}
            <Link to="/contact" style={{ color: "inherit" }}>
              <Button color="inherit">Contact</Button>
            </Link>
          </Hidden>
          {authUser && (
            <>
              <IconButton color="inherit" onClick={handleNotificationMenuOpen}>
                <Badge badgeContent={2} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              &nbsp;&nbsp;
              <IconButton
                color="inherit"
                aria-label="user profile"
                edge="end"
                onClick={handleProfileMenuOpen}
              >
                <AccountCircle />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      <SideNav {...{ mobileMenuOpen, toggleMobileMenu }} />
      {/* Notification Menu */}
      <UserNotification
        {...{ notificationMenuAnchor, handleNotificationMenuClose }}
      />
      {/* User Profile Menu */}
      <UserProfileMenu {...{ profileMenuAnchor, handleProfileMenuClose }} />
    </>
  );
};

export default Header;
