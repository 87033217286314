import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Divider,
  Link,
} from "@mui/material";
import PublicHeader from "../../components/header/publicHeader";

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <PublicHeader />
      <Container maxWidth="md" sx={{ paddingTop: 4 }}>
        <Paper sx={{ padding: 3 }}>
          <Typography variant="h4" gutterBottom>
            Privacy Policy
          </Typography>

          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            IndianYadavs.com (TnYadavs.com) Privacy Policy
          </Typography>

          <Divider sx={{ marginBottom: 2 }} />

          <Typography variant="body1" paragraph>
            IndianYadavs.com (TnYadavs.com) is an online matrimonial portal that
            caters to a comprehensive range of matchmaking services. We are
            strongly committed to your right to privacy, and have drawn out a
            privacy statement with regard to the information you share with us.
            We use a secure server for credit card transactions to protect your
            financial identity, and cookies are used to store login information.
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            What is the type of information you will share on this site?
          </Typography>
          <Typography variant="body1" paragraph>
            The information we gather from members and visitors who apply for
            the various services our site offers includes, but may not be
            limited to, email address, first name, last name, a user-specified
            password, and telephone number. If you establish a credit account
            with us to pay the fees we charge, some additional information,
            including a billing address, a credit card number, and a credit card
            expiration date will be required.
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            How will the site use the information you share?
          </Typography>
          <Typography variant="body1" paragraph>
            IndianYadavs.com (TnYadavs.com) collects information from users
            primarily to ensure fulfillment of a requirement and to deliver a
            personalized experience.
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            What are the Privacy and confidentiality procedures followed by this
            site?
          </Typography>
          <Typography variant="body1" paragraph>
            The information collected from the users is not shared with any
            individual or organization without the former's approval. The
            information collected is shared only with members of
            IndianYadavs.com (TnYadavs.com) or members of our partners.
          </Typography>
          <Typography variant="body1" paragraph>
            IndianYadavs.com (TnYadavs.com) does not sell, rent, or loan any
            identifiable information at the individual level regarding its
            customers to any third party. Any information you give us is held
            with the utmost care and security. We are also bound to cooperate
            fully should a situation arise where we are required by law or legal
            process to provide information about a customer.
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Is this a paid site?
          </Typography>
          <Typography variant="body1" paragraph>
            No. You can register free of cost. However, to view the full
            profile, you need to pay the requested amount to become a premium
            member.
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            What is the procedure to unsubscribe membership?
          </Typography>
          <Typography variant="body1" paragraph>
            The members are requested to send an email to{" "}
            <Link href="mailto:admin@Indianyadavs.com">
              admin@Indianyadavs.com
            </Link>{" "}
            or call +91-7845381202 to unsubscribe.
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Can a User access more than one profile on a single day?
          </Typography>
          <Typography variant="body1" paragraph>
            As a paid member of this site, you have the privilege of contacting
            hundreds of profiles. However, there is a specified limit to view
            only 5 contacts per day.
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            How often will the team at IndianYadavs.com (TnYadavs.com) contact
            the user?
          </Typography>
          <Typography variant="body1" paragraph>
            The IndianYadavs.com (TnYadavs.com) team will contact you from time
            to time to apprise you of various features and functionalities of
            the website. They will contact you for either "Service" or "Sales"
            through a variety of mediums like Email, SMS, MMS, Voice IVR, USSD,
            WAP messages, Telephonic Calls, etc. The calls made to you will be
            from a regular Mobile or Landline number which is not of the "140"
            series.
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Notice
          </Typography>
          <Typography variant="body1" paragraph>
            We may change this Privacy Policy from time to time based on your
            comments or as a result of a change of policy in our company. If you
            have any questions regarding our Privacy Statement, please write to{" "}
            <Link href="mailto:admin@Indianyadavs.com">
              admin@Indianyadavs.com
            </Link>{" "}
            or call +917845381202.
          </Typography>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
