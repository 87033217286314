import { createSelector } from "@reduxjs/toolkit"
import { selectAuthUser } from "../../sign-in/selector"

export const EditProfileSelector = createSelector(
    [selectAuthUser],
    (user) => {
        const { img, memberId } = user.profile;
        const updatedAuthUser = { ...user, img, memberId }
        return { authUser: updatedAuthUser }
    }
)