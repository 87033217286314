import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyReq } from "../../store/slices/authSlice";
import { selectAuthUser } from "./selector";
import React, { useEffect } from "react";
import { Card, Grid, Hidden } from "@mui/material";
import SignInForm from "../../components/forms/sign-in";
import PublicHeader from "../../components/header/publicHeader";
import { useStyle } from "./style";
import ExclusiveFor from "../../components/ExclusiveFor";

export default function SignIn() {
  const classes = useStyle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (authUser) navigate("/user");
    if (!authUser && accessToken) dispatch(verifyReq())
  }, [authUser, navigate]);

  const formContainer = () => {
    return <Container component="main" maxWidth="xs">
      <Hidden mdUp>
        <Box className={classes.mdUpBox}></Box>
      </Hidden>
      <Grid container>
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          <Typography component="span" className={classes.subTitle}>
            Begin a beautiful journey
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" textAlign="center">
            Login to your account
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 3 }}>
          <SignInForm />
        </Grid>
      </Grid>
    </Container>
  }

  return (
    <React.Fragment>
      <PublicHeader />
      <Grid container className={classes.formRow}>
        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.banner}></Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className={classes.formContainer}>
          {formContainer()}
        </Grid>
      </Grid>
      <ExclusiveFor/>
    </React.Fragment>
  );
}
