import React from "react";
import { Backdrop, CircularProgress, styled } from "@mui/material";
import { useSelector } from "react-redux";

// Define your custom styles using styled-components
const StyledBackdrop = styled(Backdrop)`
  && {
    z-index: 9999;
    color: #fff;
  }
`;

const Loader = () => {
  const isLoading = useSelector((state: any) => {
    let isLoading = false;
    Object.keys(state).forEach((key) => {
      if (state[key].isLoading) isLoading = true;
    });
    return isLoading;
  });
  return (
    <StyledBackdrop open={isLoading}>
      <CircularProgress color="primary" />
    </StyledBackdrop>
  );
};

export default Loader;
