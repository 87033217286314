import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ErrorMessage } from "formik";

export default function SelectInput(props: any) {
  const { name, formik, label, options } = props;
  const { errors, touched, values, handleChange } = formik;
  const isExist = options?.find((option: any) => option.id === values[name]);
  if (Array.isArray(options) && !isExist && options?.length) values[name] = "";

  return (
    <>
      {Array.isArray(options) && options.length ? (
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            id={name}
            value={values[name]}
            onChange={handleChange}
            autoComplete="off"
            error={!!(errors[name] && touched[name])}
            size="small"
            label={label}
            {...props}
          >
            {options?.map((option: any) => (
              <MenuItem key={`key-${option?.id}`} value={option?.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: "#d32f2f" }}>
            <ErrorMessage name={name} />
          </FormHelperText>
        </FormControl>
      ) : (
        `Input ${name} is loading`
      )}
    </>
  );
}
