// rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './slices/authSlice';
import { staticReducer } from './slices/staticSlice';
import { deviceReducer } from './slices/deviceSlice';
import { profileReducer } from './slices/profileSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    staticData: staticReducer,
    device: deviceReducer,
    profiles: profileReducer,
});

export default rootReducer;
