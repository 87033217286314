import { useState, useEffect } from "react";

export function useLocalState(initialState: any) {
    const [state, setState] = useState(initialState);
    const updateState = (newState: any) => {
        if (typeof state === 'object')
            setState({ ...state, ...newState });
        else
            setState(newState)
    };
    return [state, updateState];
}

export function useDidMount(callBack: any) {
    useEffect(callBack, [callBack])
}