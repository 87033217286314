import { createTheme } from "@mui/material";
import { purple } from '@mui/material/colors';
import { getMuiCssBaseline } from "./util";

const lightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: '#800080',
        },
        secondary: {
            main: '#C71585',
        },
    },

    components: {
        MuiBadge: {
            styleOverrides: {
                badge: {
                    color: 'white',
                },
            },
        },
        MuiCssBaseline: getMuiCssBaseline(purple) as any,
    },
});

export default lightTheme;