import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    user: null,
    theme: 'light',
    isLoading: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        themeReq: (state, action) => {
            state.theme = action.payload;
        },
        signReq: (state, action) => {
            state.user = null;
            state.isLoading = true;
        },
        signInRes: (state, action) => {
            if (action?.payload?.error)
                alert(action.payload.message)
            else
                state.user = action.payload;
            state.isLoading = false;
        },
        signInErr: (state, action) => {
            state.isLoading = false;
            alert(action.payload.message)
        },
        verifyReq: (state) => {
            state.user = null;
            state.isLoading = true;
        },
        verifyRes: (state, action) => {
            state.user = action.payload;
            state.isLoading = false;
        },
        verifyErr: (state, action) => {
            state.user = undefined;
            state.isLoading = false;
            alert(action.payload.message)
        },
        updateDPReq: (state, action) => {
            state.isLoading = true;
        },
        updateDPRes: (state, action) => {
            if (action?.payload?.error)
                return alert(action.payload.message)
            state.user.profile.img = action.payload
            state.isLoading = false;
        },
        updateDPErr: (state, action) => {
            state.isLoading = false;
            alert(action.payload.message)
        }
    },
});

export const { signReq, signInRes, signInErr, verifyReq, verifyRes, verifyErr, themeReq } = authSlice.actions;
export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
export default authSlice;
