import { takeEvery, put } from 'redux-saga/effects';
import Api from '../api';
import { staticActions } from '../slices/staticSlice';

type StaticAction = ReturnType<typeof staticActions.staticReq>;

function* loadAllStaticData(action: StaticAction): Generator {
    try {
        if (!sessionStorage.getItem('static-data')) {
            const result: any = yield Api.getStaticData();
            sessionStorage.setItem('static-data', JSON.stringify(result.data));
            yield put(staticActions.staticRes(result.data));
        } else {
            const response = JSON.parse(sessionStorage.getItem('static-data')!);
            yield put(staticActions.staticRes(response));
        }
    } catch (error: any) {
        yield put(staticActions.staticErr({ message: error.message }));
    }
}

function* staticSaga() {
    yield takeEvery(staticActions.staticReq.type, loadAllStaticData);
}

export default staticSaga;