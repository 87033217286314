import { BrowserRouter, HashRouter } from "react-router-dom";
import Footer from "./components/footer";
import Broutes from "./routes";
import { ThemeProvider, CssBaseline, Box, Hidden } from "@mui/material";
import { darkTheme, lightTheme } from "./themes";
import Loader from "./components/loader";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Device from "./components/device";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { themeReq } from "./store/slices/authSlice";

function App() {
  const dispatch = useDispatch()
  const theme = useSelector((state: any) => state.auth.theme);
  useEffect(() => {
    const theme = localStorage.getItem("theme");
    dispatch(themeReq(theme));
  }, [dispatch]);

  return (
    <HashRouter>
      <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <Hidden mdDown>
            <Box sx={{ height: "50px" }} />
          </Hidden>
          <Broutes />
          {/* <Footer /> */}
          <Loader />
          <Device />
        </LocalizationProvider>
      </ThemeProvider>
    </HashRouter>
  );
}

export default App;
