import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    list: [],
    listName: 'all',
    currentPage: 1,
    itemPerPage: 12,
    totalItems: 0,
    isLoading: false,
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        profileReq: (state, action) => {
            state.isLoading = true;
            const { isInit, listName, isSearchInit } = action.payload;
            if (isInit || isSearchInit) {
                state.list = [];
                state.listName = listName;
            }
        },
        profileRes: (state, action) => {
            const { error, message, items, currentPage, totalItems } = action.payload;
            state.isLoading = false;
            if (error) return alert(message)
            if (items.length) state.list = [...state.list, ...items];
            else state.list = items
            state.currentPage = currentPage;
            state.totalItems = totalItems;
        },
        profileErr: (state, action) => {
            state.isLoading = false;
            alert(action.payload.message)
        },
        sendIntReq: (state, action) => {
            state.isLoading = true;
        },
        sendIntRes: (state, action) => {
            if (action.payload === true) alert("Interest sent successfully")
            const user = JSON.parse(sessionStorage.getItem('selectedProfile') || '');
            const index = state.list.findIndex((i: any) => i.id === user.id)
            state.list[index].isInterestSent = 1;
            user.isInterestSent = 1;
            sessionStorage.setItem('selectedProfile', JSON.stringify(user))
            state.isLoading = false;
        },
        sendIntErr: (state, action) => {
            state.isLoading = false;
        }
    },
});

export const { profileReq, profileRes, profileErr } = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
export const profileActions = profileSlice.actions;
export default profileSlice;
