import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
    selected: {
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: "50px !important",
        height: "50px !important",
        marginTop: -30,
        padding: 10,
    },
    paper: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
    }
}));

export default useStyles;