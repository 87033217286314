import { createSelector } from "@reduxjs/toolkit";

export const getInitalValues = () => {
    const preference = sessionStorage.getItem('preference');
    if (preference) return JSON.parse(preference)
    return {
        memberId: '',
        ageFrom: '',
        ageTo: '',
        countries: [],
        states: [],
        cities: [],
        nativeCountries: [],
        nativeStates: [],
        nativeCities: [],
        religions: [],
        castes: [],
        subCastes: [],
        maritalStatus: [],
        economicStatuses: [],
        qualifications: [],
        professions: [],
        stars: [],
        sevvaiDhosam: false,
        raghuKethuDhosam: false,
        physicalStatus: []
    }
}

export const SearchSelector = createSelector(
    (state: any) => state.staticData,
    (state: any) => state.auth.user,
    (staticData: any, user) => {
        return {
            ...staticData,
            nativeStates: staticData.states,
            nativeDistricts: staticData.districts,
            search: user.profile
        }
    }
);