import { Color } from "@mui/material";

export const getMuiCssBaseline = (color: Color) => {
    return {
        styleOverrides: {
            '*::-webkit-scrollbar': {
                width: '1px',
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: color[500],
                borderRadius: '10px',
            },
            '*::-webkit-scrollbar-thumb:hover': {
                backgroundColor: color[500],
            },
            '*::-webkit-scrollbar-track': {
                backgroundColor: 'gray',
            },
            scrollbarWidth: 'thin',
        },
    };
}