import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { useLocalState } from "../../hooks";

const notifications = [
  { id: 1, text: "New message received", time: "10 minutes ago" },
  { id: 2, text: "You have a meeting in 15 minutes", time: "2 hours ago" },
];

export default function UserNotification({
  notificationMenuAnchor,
  handleNotificationMenuClose,
}: any) {
  const [state] = useLocalState({ notifications: notifications });

  return (
    <Menu
      anchorEl={notificationMenuAnchor}
      open={Boolean(notificationMenuAnchor)}
      onClose={handleNotificationMenuClose}
    >
      <Container maxWidth="xs">
        <Grid container>
          {state.notifications.map((notification: any) => (
            <Grid item key={notification.id} xs={12}>
              <MenuItem>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={notification.text}
                  secondary={
                    <>
                      <AccessTimeIcon
                        fontSize="small"
                        style={{ verticalAlign: "text-bottom" }}
                      />
                      {notification.time}
                    </>
                  }
                />
              </MenuItem>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Menu>
  );
}
