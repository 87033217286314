import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { selectAuthUser } from "../sign-in/selector";
import { useEffect } from "react";
import { verifyReq } from "../../store/slices/authSlice";
import BottomNav from "../../components/mobile/bottom-nav";
import { useLocalState } from "../../hooks";
import Header from "../../components/header";
import SimpleHeader from "../../components/header/SimpleHeader";


const simpleHeaderRoutes = [
  "/user/edit-profile",
  "/user/view-profile",
  "/user/make-payment",
  "/user/recent-views",
  "/user/settings",
]

export default function User() {
  const dispatch = useDispatch();
  const location = useLocation();
  const authUser = useSelector(selectAuthUser);
  const [LS, updateState] = useLocalState({ isSimpleHeader: false });

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!authUser && accessToken) dispatch(verifyReq());
    if (!authUser && !accessToken) window.location.href = "/";
  }, [dispatch, authUser]);

  useEffect(() => {
    updateState({ isSimpleHeader: simpleHeaderRoutes.includes(location.pathname) })
  }, [location.pathname]);

  return (
    <>
      {authUser ? (
        <>
          {LS.isSimpleHeader ? <SimpleHeader /> : <Header />}
          <Outlet />
          {!LS.isSimpleHeader && <BottomNav />}
        </>
      ) : (
        "Loading..."
      )}
    </>
  );
}
