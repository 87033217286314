import { Box, CssBaseline, Grid, Hidden } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PhotoLibrary from "@mui/icons-material/PhotoLibrary";
import PhoneIcon from "@mui/icons-material/Phone";
import NoteIcon from "@mui/icons-material/Note";
import DescriptionIcon from "@mui/icons-material/Description";
import useStyles from "../../../components/profile-detail/style";
import { useLocalState } from "../../../hooks";
import Gallery from "../../../components/profile-detail/gallery";
import Contact from "../../../components/profile-detail/contact";
import ProfileForm from "../../../components/forms/profile";
import { useDispatch, useSelector } from "react-redux";
import { EditProfileSelector } from "./selector";
import ProfileImage from "../../../components/profile-image";
import Api from "../../../store/api";
import { authActions, verifyReq } from "../../../store/slices/authSlice";

export default function EditProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [LS, setLS] = useLocalState({ tabValue: "1" });
  const RS = useSelector(EditProfileSelector);
  const switchTab = (event: any, newValue: any) => {
    setLS({ tabValue: newValue });
  };
  window.scrollTo(0, 0);

  const handleProfileImageUpdate = async (file: Blob) => {
    dispatch(authActions.updateDPReq({ file, userId: RS.authUser.id }))
  }

  return (
    <>
      <Hidden mdUp implementation="css">
        <Box sx={{ height: "50px" }} />
      </Hidden>
      <Paper elevation={0} className={classes.paper}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <ProfileImage
              currentImage={RS?.authUser?.img}
              onUpdate={handleProfileImageUpdate} />
            {/* Profile Details Content */}
            <Typography align="center" variant="h6">
              {RS?.authUser?.profile?.memberId}
            </Typography>
            <TabContext value={LS.tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={switchTab}
                  aria-label="lab API tabs example"
                  className={classes.tabList}
                  centered
                >
                  <Tab icon={<DescriptionIcon />} value="1" />
                  <Tab icon={<PhotoLibrary />} value="2" />
                  <Tab icon={<PhoneIcon />} value="3" />
                  <Tab icon={<NoteIcon />} value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <ProfileForm />
                <Box className={classes.blank}></Box>
              </TabPanel>
              <TabPanel value="2">
                <Gallery user={RS.authUser} isDeletable={true} />
                <Box className={classes.blank}></Box>
              </TabPanel>
              <TabPanel value="3">
                <Contact user={RS.authUser} />
              </TabPanel>
              <TabPanel value="4">
                Notes Section
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
