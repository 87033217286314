import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Avatar, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { CropperRef, FixedCropper, ImageRestriction } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'
import Api from '../../store/api';
import defaultImage from '../../default.jpg'

const ProfileImageEdit = ({ currentImage, onUpdate }: any) => {
    const [open, setOpen] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const [file, setFile] = useState<Blob>()

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNewImage(null);
    };

    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader: any = new FileReader();
            reader.onloadend = () => {
                setNewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpdate = () => {
        onUpdate(file);
        handleClose();
    };

    const onChange = (cropper: CropperRef) => {
        const canvas = cropper.getCanvas() || cropper.getCanvas({
            height: 300,
            width: 400,
        })
        if (canvas) setFile(Api.base64ToBinary(canvas.toDataURL('image/jpeg')))
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ position: 'relative', display: 'inline-block', margin: 'auto' }}>
                {currentImage && <>
                    <Avatar
                        src={currentImage}
                        alt="Profile"
                        sx={{ width: 100, height: 100, zIndex: 1 }} />
                    <IconButton
                        color="primary"
                        onClick={handleOpen}
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            zIndex: 2,
                            backgroundColor: 'white',
                            border: '1px solid gray'
                        }}>
                        <EditIcon />
                    </IconButton>
                </>}
                {!currentImage && <Button variant='contained' onClick={handleOpen}>Upload more images</Button>}
            </Box>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Edit Profile Image</DialogTitle>
                <DialogContent style={{ height: '600px' }}>
                    <input type="file" accept="image/*" onChange={handleImageChange} />
                    <FixedCropper
                        src={newImage || defaultImage}
                        stencilSize={{
                            width: 300,
                            height: 400
                        }}
                        stencilProps={{
                            handlers: true,
                            lines: false,
                            movable: true,
                            resizable: false
                        }}
                        imageRestriction={ImageRestriction.stencil}
                        onChange={onChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleUpdate} disabled={!newImage} variant="contained" color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ProfileImageEdit;
