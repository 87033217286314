import { Box, Grid, Hidden } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PhotoLibrary from "@mui/icons-material/PhotoLibrary";
import PhoneIcon from "@mui/icons-material/Phone";
import NoteIcon from "@mui/icons-material/Note";
import DescriptionIcon from "@mui/icons-material/Description";
import useStyles from "../../../components/profile-detail/style";
import Details from "../../../components/profile-detail/details";
import Gallery from "../../../components/profile-detail/gallery";
import Contact from "../../../components/profile-detail/contact";
import Actions from "../../../components/profile-detail/actions";
import Notes from "./notes";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useLocalState } from "../../../hooks";

export default function ViewProfile() {
  const classes = useStyles();
  const navigate = useNavigate();
  const selectedProfile = sessionStorage.getItem('selectedProfile');
  const user = selectedProfile ? JSON.parse(selectedProfile) : null;
  useEffect(() => {
    console.log(user)
    if (!user) navigate('/')
  }, [])

  const [LS, setLS] = useLocalState({ tabValue: "1" });
  const switchTab = (event: any, newValue: any) => {
    setLS({ tabValue: newValue });
  };
  window.scrollTo(0, 0);

  return (
    <>
      <Hidden mdUp>
        <Box sx={{ height: "50px" }} />
      </Hidden>
      <Paper elevation={0} className={classes.paper}>
        {user && <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Avatar
              alt="Profile Image"
              src={user?.img}
              sx={{ width: 100, height: 100, margin: "auto" }}
            />
            {/* Profile Details Content */}
            <Typography align="center" variant="h6">
              {user?.memberId}
            </Typography>
            <TabContext value={LS.tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={switchTab}
                  aria-label="lab API tabs example"
                  className={classes.tabList}
                  centered
                >
                  <Tab icon={<DescriptionIcon />} value="1" />
                  <Tab icon={<PhotoLibrary />} value="2" />
                  <Tab icon={<PhoneIcon />} value="3" />
                  <Tab icon={<NoteIcon />} value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Details profileData={user} />
                <Box className={classes.blank}></Box>
              </TabPanel>
              <TabPanel value="2">
                <Gallery user={user} />
                <Box className={classes.blank}></Box>
              </TabPanel>
              <TabPanel value="3">
                <Contact user={user} />
              </TabPanel>
              <TabPanel value="4">
                <Notes />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>}
      </Paper>
      <Actions />
    </>
  );
}
