import React from 'react'
import PageWrapper from '../../../components/page-wrapper'
import Api from '../../../store/api'
import { useSelector } from 'react-redux'
import { PaymentPageSelector } from '../../../store/selector';
import { useLocalState } from '../../../hooks';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';

export default function Payment() {
  const navigate = useNavigate();
  const RS = useSelector(PaymentPageSelector);
  const { authUser } = RS;
  const [LS, updateState] = useLocalState({ package: null })
  const formContainer = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    Api.getPayment(authUser.id).then((response: any) => {
      updateState({ package: response.data })
    })
  }, [])

  const handlePay = () => {
    if (!LS.package) {
      alert('Complete your profile detials')
      navigate('/user/edit-profile');
      return false;
    }
    const { price } = LS.package;
    const { id, firstName, lastName, email, mobile } = authUser
    const name = `${firstName} ${lastName}`;
    const country = 'INDIA';
    const state = 'TAMILNADU';
    const order_id = Math.round(new Date().getTime() / 1000) + id;
    const baseUrl = process.env.REACT_APP_API_URL;
    const responseHandler = baseUrl + '/v1/ccavenue/responseHandler';
    const tax = price * 0.18;
    const payload = {
      merchant_id: '1769853',
      order_id: order_id,
      currency: 'INR',
      amount: price + tax,
      redirect_url: responseHandler,
      cancel_url: responseHandler,
      language: '',
      billing_name: name,
      billing_address: '',
      billing_city: '',
      billing_state: state,
      billing_zip: '',
      billing_country: country,
      billing_tel: mobile,
      billing_email: email,
      delivery_name: name,
      delivery_address: '',
      delivery_city: '',
      delivery_state: state,
      delivery_zip: '',
      delivery_country: country,
      delivery_tel: mobile,
      merchant_param1: id,
      merchant_param2: LS.package.id,
      merchant_param3: '',
      merchant_param4: '',
      merchant_param5: '',
      promo_code: '',
      customer_identifier: '',
    };
    Api.ccAvenueReq(payload).then((response: any) => {
      const { encRequest, accessCode, redirectUrl } = response.data;
      if (formContainer.current) {
        formContainer.current.innerHTML = `
        <form id="nonseamless" method="post" name="redirect" action="${redirectUrl}"> 
          <input type="hidden" id="encRequest" name="encRequest" value="${encRequest}">
          <input type="hidden" name="access_code" id="access_code" value="${accessCode}">
        </form>`;
        const customeDoc: any = document;
        if (customeDoc?.redirect) customeDoc.redirect.submit();
      }
    });
  }

  return (
    <PageWrapper>
      {LS.package && <>
        <h2 style={{ margin: 0, textAlign: 'center' }}>Please Make Payment</h2>
        <p style={{ margin: 0, textAlign: 'center', color: 'green', fontSize: '13px' }}>
          To view unlimited profile & contact details <br />for {LS.package.validity} days
        </p>
        <Grid container>
          <Grid item xs={12} md={3} lg={4}></Grid>
          <Grid item xs={12} md={6} lg={4} component={Paper} elevation={3} sx={{ marginTop: '10px' }}>
            <table style={{ width: '100%', padding: '12px' }}>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left' }}><b>{authUser.firstName}</b></td>
                  <td style={{ textAlign: 'center' }}>&nbsp;</td>
                  <td style={{ textAlign: 'right' }}>{Api.getToday()}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }}>({authUser.countryCode}) {authUser.mobile}</td>
                  <td style={{ textAlign: 'center' }}>&nbsp;</td>
                  <td style={{ textAlign: 'right' }}>Ref #: 34522677W</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <h2 style={{ textAlign: 'center', margin: '0' }}>Receipt</h2>
                  </td>
                </tr>
              </tbody>
            </table>
            <TableContainer sx={{ marginTop: '-20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell size="small"><b>Package</b></TableCell>
                    <TableCell size="small"><b>#</b></TableCell>
                    <TableCell size="small"><b>Price</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell size="small" sx={{ border: 'none' }}>{LS.package.name}</TableCell>
                    <TableCell size="small" sx={{ border: 'none' }}>{1}</TableCell>
                    <TableCell size="small" sx={{ border: 'none' }}>{LS.package.price}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" sx={{ border: 'none' }}></TableCell>
                    <TableCell size="small">Tax</TableCell>
                    <TableCell size="small">{LS.package.price * 0.18}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" sx={{ border: 'none' }}></TableCell>
                    <TableCell size="small">Total</TableCell>
                    <TableCell size="small">
                      {(LS.package.price * 0.18) + LS.package.price}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ textAlign: 'center', width: '100%', margin: '10px 0px 10px 0px' }}>
              <Button size='small' variant='contained' onClick={handlePay}>Pay Now</Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} lg={4}></Grid>
        </Grid>
        <p style={{ textAlign: 'center', fontSize: 10, color: 'red' }}>
          Note: <br />
          PLEASE BE HONEST IN CHOOSING FAMILY ECONOMIC STATUS. <br />
          Profile filter will be applied based on this
        </p>
        <div ref={formContainer}></div>
      </>}
    </PageWrapper>
  )
}
