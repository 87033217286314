import { Box, Button, Container, Grid, Link } from "@mui/material";
import { Form, Formik } from "formik";
import TextInput from "../inputs/text-input";
import { OTPFormValidationSchema } from "./validation";
import { useEffect } from "react";
import { useLocalState } from "../../hooks";
import { IAction, IOtpForm, IProps } from "./types";
import Api from "../../store/api";
import { useDispatch, useSelector } from "react-redux";
import { signInRes, verifyReq } from "../../store/slices/authSlice";
import { selectAuthUser } from "../../pages/sign-in/selector";
import { useNavigate } from "react-router-dom";

export default function SignUpOtp(props: IProps) {
  const { payload } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Local State */
  const [LS, setState] = useLocalState({ countDown: 60 });
  /* redux state */
  const authUser = useSelector(selectAuthUser);

  // life cycles
  useEffect(() => {
    const timer = setTimeout(() => {
      if (LS.countDown > 0) setState({ countDown: LS.countDown - 1 });
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (authUser) navigate("/user");
    if (!authUser && accessToken) dispatch(verifyReq())
  }, [authUser, navigate]);

  const onResendClick = async () => {
    if (LS.countDown) return;
    const otpResponse = await Api.sendSignUpOTP({ mobile: payload.mobile || '' })
    if (otpResponse?.status === 200) alert(otpResponse?.data.message)
    setState({ countDown: 60 });
  };

  const handleOTPForm = async (values: IOtpForm, action: IAction) => {
    const newPayload = { ...payload, otp: values.otp }
    const verifyOTPRes = await Api.verifySignUpOTP(newPayload);
    const { statusCode, message, data } = verifyOTPRes.data;
    if (statusCode === 409) {
      alert(message)
      navigate("/sign-in")
    }
    else dispatch(signInRes(data))
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Formik
          validationSchema={OTPFormValidationSchema}
          initialValues={{ otp: "" }}
          onSubmit={handleOTPForm}
        >
          {(formik: any) => (
            <Box component={Form} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <TextInput
                    autoFocus
                    name="otp"
                    label="One Time Password"
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Verify OTP
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link component={"span"} variant="body2">
                    If not received?{" "}
                    <Button
                      sx={{
                        color: LS.countDown ? "gray" : "green",
                        cursor: "pointer",
                      }}
                      onClick={onResendClick}
                    >
                      <b>RESEND</b>
                    </Button>{" "}
                    after{" "}
                    <span style={{ color: "green" }}>
                      <b>
                        {LS.countDown < 10 ? "0" : ""}
                        {LS.countDown}
                      </b>
                    </span>{" "}
                    seconds
                  </Link>
                </Grid>
              </Grid>
            </Box>
          )}
        </Formik>
      </Box>
    </Container>
  );
}
