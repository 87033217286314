import React from 'react';
import { Theme } from '@mui/material/styles';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { makeStyles } from '@mui/styles';
import PublicHeader from '../../components/header/publicHeader';


const useStyles = makeStyles((theme: Theme) => ({
    card: {
        minWidth: 275,
        textAlign: 'center',
        margin: theme.spacing(2),
        boxShadow: 'none',
        border: `2px dotted ${theme.palette.error.main}`,
        height: '200px',
        marginTop:'100px'
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.error.main,

    },
    textColor: {
        color: '#221668' // Blue color for address text
    },
}));

const Contact: React.FC = () => {
    
    const classes = useStyles();

    return (
       <>
        <PublicHeader/>

    <Grid container spacing={2} sx={{ pl: '5%', pr: '5%' }} >
        {/* Address */}
        <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
                <CardContent sx={{ contentAlign: "center" }} >
                    <LocationOnIcon className={classes.icon} />
                    <Typography className={classes.textColor} variant="body1">Address</Typography>
                    <Typography mt={4} variant="subtitle1">No 4, Thirumazhisai Via, Pudhuchatram Post, Poonamallee Taluk. Chennai - 600124</Typography>
                </CardContent>
            </Card>
        </Grid>

        {/* Mobile */}
        <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
                <CardContent>
                    <PhoneIcon className={classes.icon} />
                    <Typography className={classes.textColor} variant="body1">Mobile</Typography>
                    <Typography mt={4} variant="subtitle1">+91 7845381202</Typography>
                </CardContent>
            </Card>
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
                <CardContent>
                    <MailIcon className={classes.icon} />
                    <Typography className={classes.textColor} variant="body1">Email</Typography>
                    <Typography mt={4} variant="subtitle1">Email:info@tnyadavs.com</Typography>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </>
    );
};

export default Contact;
