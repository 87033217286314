import { authorizedAxios, authorizedMultpartAxios, publicAxios } from "./axiosConfig";
import defaultImage from '../../default.jpg'

const Api = {
    getUserWithProfile: async (response: any) => {
        const { id, accessToken, error } = response.data;
        if(error) return response;
        localStorage.setItem("accessToken", accessToken)
        response.data.profile = await Api.getProfileDetails(id)
        return response
    },
    signIn: async (payload: any) => {
        payload = { ...payload, deviceType: 'WEB' }
        const signInUrl = '/auth/sign-in';
        const response = await publicAxios.post(signInUrl, payload);
        return await Api.getUserWithProfile(response);
    },
    sendSignInOTP: async (payload: any) => {
        const url = '/auth/send/otp/v1';
        return await publicAxios.post(url, payload);
    },
    verifySignInOTP: async (payload: any) => {
        const url = '/auth/sign-in/otp/v1';
        const response = await publicAxios.post(url, payload);
        return await Api.getUserWithProfile(response);
    },
    validateToken: async () => {
        const url = "/v1/users/validate-token";
        const response = await authorizedAxios.post(url, {});
        return await Api.getUserWithProfile(response);
    },
    getStaticData: async () => {
        const url = "/v2/static-data";
        return await publicAxios.get(url);
    },
    searchProfiles: async ({ limit, page, payload }: any) => {
        const response = await authorizedAxios.post(`/v2/search?limit=${limit}&page=${page}`, payload)
        const { items } = response.data
        const imgRequests = [];
        if (items?.length) {
            for (const item of items) {
                imgRequests.push(Api.getProfileImage(item.id))
            }
        }
        const images = await Promise.all(imgRequests);
        images.forEach((img, index) => {
            response.data.items[index].img = img
        })
        return response
    },
    getAllProfiles: async ({ limit, page, listName, isInit }: any) => {
        if (listName === 'search' && isInit) {
            return { data: { items: [], currentPage: page, itemPerPage: limit, totalItems: 0, } }
        }
        const preference = sessionStorage.getItem('preference')
        if (listName === 'search' && preference) {
            const payload = JSON.parse(preference)
            return await Api.searchProfiles({ limit, page, payload })
        }
        let url = "";
        if (listName === 'all' || listName === 'search')
            url = `/v2/profiles?limit=${limit}&page=${page}`;
        if (listName === 'viewers')
            url = `/v2/who-viewed-me?limit=${limit}&page=${page}`;
        if (listName === 'sent')
            url = `/v2/interest-sent?limit=${limit}&page=${page}`;
        if (listName === 'received')
            url = `/v2/interest-received?limit=${limit}&page=${page}`;
        if (listName === 'recent')
            url = `/v2/recently-viewed-by-me?limit=${limit}&page=${page}`;
        const response = await authorizedAxios.get(url);
        const { items } = response.data
        const imgRequests = [];
        if (items?.length) {
            for (const item of items) {
                imgRequests.push(Api.getProfileImage(item.id))
            }
        }
        const images = await Promise.all(imgRequests);
        images.forEach((img, index) => {
            response.data.items[index].img = img
        })
        return response
    },
    getProfileImage: async (id: number) => {
        const url = `/v1/galleries/profile-image/${id}`;
        const res = await authorizedAxios.get(url);
        const { fileType, content } = res.data;
        if (fileType && content) {
            const dataUri = `data:${fileType};base64,${content}`;
            return dataUri;
        } else {
            return defaultImage;
        }
    },
    getFile: async (fileName: string, fileType: string, userId: number) => {
        const url = `/v1/galleries/file/${userId}/${fileName}`
        const res = await authorizedAxios.get(url);
        const { file } = res.data;
        return `data:${fileType};base64,${file}`
    },
    getGalleryImages: async (userId: number) => {
        const url = `/v1/galleries/list/${userId}`;
        const res = await authorizedAxios.get(url);
        const imgRequests = [];
        if (res?.data?.length) {
            for (const item of res.data) {
                const { fileName, fileType } = item;
                imgRequests.push(Api.getFile(fileName, fileType, userId))
            }
        }
        const images = await Promise.all(imgRequests);
        return images.map((image, index) => {
            return {
                id: res?.data[index]?.id || index + 1,
                alt_description: "Image",
                url: image
            }
        })
    },
    getProfileDetails: async (userId: number) => {
        const url = `/v1/profiles/getByUserId/${userId}`;
        const reqests = [
            authorizedAxios.get(url),
            Api.getProfileImage(userId)
        ]
        const [profile, image]: any = await Promise.all(reqests)
        if (profile && typeof profile === 'object')
            profile.data['img'] = image;
        return profile.data;
    },
    updatePofileDetails: async (payload: any) => {
        const url = `/v1/profiles`;
        const response = await authorizedAxios.post(url, payload);
        return response;
    },
    changePassword: async (payload: any) => {
        const url = `/v1/users/changePassword`;
        return await authorizedAxios.post(url, payload)
    },
    compressImage: (dataUri: string, w: number, h: number) => {
        return new Promise((resolve, reject) => {
            try {
                const image = new Image();
                image.src = dataUri;
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = w;
                    canvas.height = h;
                    const ctx = canvas.getContext('2d');
                    if (ctx) ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                    resolve({ error: false, imageUri: canvas.toDataURL('image/png') })
                }
            } catch (error: any) {
                reject({ error: true, message: error.message })
            }
        })
    },
    base64ToBinary(base64String: string) {
        const binaryString = atob(base64String.split(',')[1]);
        const length = binaryString.length;
        const binaryArray = new Uint8Array(length);
        for (let i = 0; i < length; i++)
            binaryArray[i] = binaryString.charCodeAt(i);
        return new Blob([binaryArray], { type: 'image/jpeg' });
    },
    updateProfileImage: async (payload: { file: Blob, userId: string }) => {
        const { file, userId } = payload;
        const formData = new FormData();
        formData.append('file', file, `profile_image_${userId}.jpg`)
        formData.append('userId', userId);
        formData.append('galleryType', 'PROFILE_IMAGE');
        const response = await authorizedMultpartAxios.post('/v1/galleries', formData)
        if (response.status === 200) {
            const img = await Api.getProfileImage(parseInt(userId))
            return { img, status: 200 }
        }
        return response
    },
    uploadGallaryImage: async (payload: { file: Blob, userId: string }) => {
        const { file, userId } = payload;
        const formData = new FormData();
        formData.append('file', file, `gallary_image_${userId}.jpg`)
        formData.append('userId', userId);
        formData.append('galleryType', 'GALLERY_IMAGE');
        const response = await authorizedMultpartAxios.post('/v1/galleries', formData)
        if (response.status === 200) {
            const images = await Api.getGalleryImages(parseInt(userId))
            return { images, status: 200 }
        }
        return response
    },
    trashImage: async (payload: { imageId: number, userId: number }) => {
        const { imageId, userId } = payload;
        const deleteApiUrl = `/v1/galleries/${imageId}`;
        return await authorizedAxios.delete(deleteApiUrl)
    },
    getPayment: async (userId: number) => {
        const apiUrl = `/v1/packages/getByUserId/${userId}`;
        return await authorizedAxios.get(apiUrl);
    },
    sendSignUpOTP: async (payload: { mobile: string }) => {
        const apiUrl = `/auth/send/otp/v1`;
        return await publicAxios.post(apiUrl, payload);
    },
    verifySignUpOTP: async (payload: any) => {
        const apiUrl = '/auth/verify/otp/v1';
        const response = await publicAxios.post(apiUrl, payload);
        return await Api.getUserWithProfile(response);
    },
    setViewed: async (profileId: number) => {
        const apiUrl = `/v2/set-viewed/${profileId}`;
        return await authorizedAxios.post(apiUrl, {})
    },
    sendInterest: async (profileId: number) => {
        const apiUrl = `/v2/set-interest/${profileId}`;
        return await authorizedAxios.post(apiUrl, {})
    },
    debounce: <T extends (...args: any[]) => void>(
        func: T,
        delay: number
    ) => {
        let timeout: NodeJS.Timeout;
        return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), delay);
        };
    },
    getToday: () => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const today = new Date();
        const day = today.getDate();
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        return `${day}-${month}-${year}`;
    },
    ccAvenueReq: async (payload: any) => {
        const apiUrl = `/v1/ccavenue/requestHandler`;
        return await authorizedAxios.post(apiUrl, payload)
    }
}
export default Api;