import { takeEvery, put } from 'redux-saga/effects';
import Api from '../api';
import { profileActions } from '../slices/profileSlice';

type StaticAction = ReturnType<typeof profileActions.profileReq>;
type InterestAction = ReturnType<typeof profileActions.sendIntReq>;

function* loadAllProfileData(action: StaticAction): Generator {
    try {
        const result: any = yield Api.getAllProfiles(action.payload);
        yield put(profileActions.profileRes(result.data));
    } catch (error: any) {
        yield put(profileActions.profileErr({ message: error.message }));
    }
}

function* sendInterest(action: InterestAction): Generator {
    try {
        const result: any = yield Api.sendInterest(action.payload);
        yield put(profileActions.sendIntRes(result.data));
    } catch (error: any) {
        yield put(profileActions.sendIntErr({ message: error.message }));
    }
}

function* profileSaga() {
    yield takeEvery(profileActions.profileReq.type, loadAllProfileData);
    yield takeEvery(profileActions.sendIntReq.type, sendInterest);
}

export default profileSaga;