import { createTheme } from "@mui/material";
import { purple } from "@mui/material/colors";
import { getMuiCssBaseline } from "./util";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        secondary: {
            main: '#C71585',
        },
    },
    components: {
        MuiCssBaseline: getMuiCssBaseline(purple) as any,
    }
});

export default darkTheme;