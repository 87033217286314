import { useDispatch, useSelector } from "react-redux";
import ProfileCard from "../../../components/profile-card";
import Grid from "@mui/material/Grid";
import { useEffect, useCallback } from "react";
import { profileReq } from "../../../store/slices/profileSlice";
import { DashboardSelector } from "./selector";
import PageWrapper from "../../../components/page-wrapper";
import Search from "../../../components/forms/search";
import { useNavigate } from "react-router-dom";
import Api from "../../../store/api";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const RS = useSelector(DashboardSelector);

  const paginate = (limit: number, page: number) => {
    dispatch(profileReq({ limit, page, listName: RS.listName }));
  };

  // eslint-disable-next-line
  const handleScroll = useCallback(
    Api.debounce(() => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 100 && !RS.isLoading) {
        const totalPages = Math.ceil(RS.totalItems / 12);
        if (RS.currentPage < totalPages) paginate(12, RS.currentPage + 1);
      }
    }, 200),
    [RS.isLoading, RS.currentPage, paginate]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const onProfileClick = async (user: any) => {
    const result: any = await Api.compressImage(user.img, 300, 300)
    if (!result?.error) {
      const newUser = { ...user, img: result.imageUri }
      sessionStorage.setItem('selectedProfile', JSON.stringify(newUser));
      navigate('/user/view-profile')
    } else { alert("try again later") }
  };

  useEffect(() => {
    if (sessionStorage.getItem('selectedProfile'))
      sessionStorage.removeItem('selectedProfile')
  }, [])

  const onSearch = (searchValues: any) => {
    sessionStorage.setItem('preference', JSON.stringify(searchValues))
    const payload = { limit: 12, page: 1, isInit: false, isSearchInit: true, listName: "search" };
    dispatch(profileReq(payload));
  }

  return (
    <PageWrapper>
      {RS.listName === 'search' && <Search onSearch={onSearch} />}
      <br />
      <Grid container spacing={3}>
        {RS.list.map((user: any, index: number) => {
          return (
            <Grid key={`key-${index + 1}`} item xs={12} sm={6} md={4} lg={3}>
              <ProfileCard onProfileClick={onProfileClick} user={user} />
            </Grid>
          );
        })}
      </Grid>
    </PageWrapper>
  );
}
