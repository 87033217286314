import * as Yup from 'yup';
import { IForm } from './types';

const phoneRegExp = /^[2-9][0-9]{9}$/;
const incomeRegExp = /^[0-9]+$/;

const SearchSchema: Yup.Schema<IForm> = Yup.object().shape({
    memberId: Yup.string().optional(),
    maritalStatus: Yup.array().optional(),
    ageFrom: Yup.number().optional(),
    ageTo: Yup.number().optional(),
    countries: Yup.array().optional(),
    states: Yup.array().optional(),
    cities: Yup.array().optional(), 
    nativeCountries: Yup.array().optional(),
    nativestates: Yup.array().optional(),
    nativeCities: Yup.array().optional(),
    religions: Yup.array().optional(),
    castes: Yup.array().optional(),
    subCastes: Yup.array().optional(),
    stars: Yup.array().optional(),
    qualifications: Yup.array().optional(),
    professions: Yup.array().optional(),
    economicStatuses: Yup.array().optional(),
    physicalStatus: Yup.array().optional(),
    dhosam: Yup.array().optional(),

});

export default SearchSchema;