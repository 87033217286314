
import { createSelector } from "@reduxjs/toolkit";
import { ISignUpForm } from "./types";
import { AuthUserSelector, StaticDataSelector } from "../../store/selector";

export const initialValues: ISignUpForm = {
    firstName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    motherTongue: "",
    caste: "",
    subCaste: "",
    qualification: "",
    profession: "",
    country: "",
    state: "",
    district: "",
    salaryDetails: "",
    economicStatus: "",
    username: "",
    deviceType: 'WEB',
    onBehalf: 1,
    countryCode: '+91'
}

export const SignUpSelector = createSelector(
    [AuthUserSelector, StaticDataSelector],
    (authUser, staticData) => {
        return { authUser, staticData }
    }
);