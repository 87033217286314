// store.ts
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import { all } from 'redux-saga/effects';
import authSaga from './sagas/authSaga';
import staticSaga from './sagas/staticSaga';
import profileSaga from './sagas/profileSaga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(function* () {
  yield all([authSaga(), staticSaga(), profileSaga()]);
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
