import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import SignUpValidationSchema from "./validation";
import { SignUpSelector, initialValues } from "./selector";
import { IActions, ISignUpForm } from "./types";
import TextInput from "../../components/inputs/text-input";
import SelectInput from "../../components/inputs/select-input";
import DateInput from "../../components/inputs/date-input";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { staticReq } from "../../store/slices/staticSlice";
import DynamicModal from "../../components/modal";
import SignUpOtp from "../../components/sign-up-otp";
import { Hidden } from "@mui/material";
import Api from "../../store/api";
import PublicHeader from "../../components/header/publicHeader";
import { verifyReq } from "../../store/slices/authSlice";
import { useStyle } from "./style";
import ExclusiveFor from "../../components/ExclusiveFor";

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyle();

  // Local State
  const defaultState = {
    states: [{}],
    districts: [{}],
    castes: [{}],
    subCastes: [{}],
    modalOpen: false,
    payload: null,
  };
  const [LS, setLState] = useState<any>(defaultState);
  const updateState = (newState: any) => setLState({ ...LS, ...newState });

  // Redux State
  const RS = useSelector(SignUpSelector);
  const { authUser, staticData } = RS;
  // Life cycles
  useEffect(() => {
    dispatch(staticReq());
  }, [dispatch]);

  // Utility functions
  const loadSelectOptions = (value: number, field: string, source: string) => {
    const filter = (item: any) => item[field] === value;
    updateState({ [source]: staticData[source].filter(filter) });
  };

  // load 
  const handleSignUp = async (values: ISignUpForm, actions: IActions) => {
    const otpResponse = await Api.sendSignUpOTP({ mobile: values.mobile || '' })
    if (otpResponse?.status === 200) {
      alert(otpResponse?.data.message)
      updateState({ modalOpen: true, payload: values });
    }
  };

  const onCloseModal = (event: MouseEvent, resion: string) => {
    // if (resion === 'backdropClick') return;
    updateState({ modalOpen: false });
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (authUser) navigate("/user");
    if (!authUser && accessToken) dispatch(verifyReq());
  }, [authUser, navigate]);


  const formContainer = () => {
    return <Container component="main" maxWidth="sm">
      <Hidden mdUp>
        <Box className={classes.mdUpBox}></Box>
      </Hidden>
      <Grid container>
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          <Typography component="span" className={classes.subTitle}>
            Begin a beautiful journey
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" textAlign="center">
            Sign up for new account
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: 3 }}>
          <Formik
            validationSchema={SignUpValidationSchema}
            initialValues={initialValues}
            onSubmit={handleSignUp}
          >
            {(formik: any) => (
              <Box component={Form} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      name="firstName"
                      label="First Name"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      label="Last Name"
                      name="lastName"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      name="username"
                      label="Mobile Number"
                      formik={formik}
                      onChange={(e: any) => {
                        const mobile = e.target.value
                        formik.setFieldValue('mobile', mobile)
                        formik.setFieldValue('email', mobile + '@mail.com')
                        formik.setFieldValue('username', mobile)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      name="gender"
                      label="Gender"
                      options={staticData.genders}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DateInput
                      name="dateOfBirth"
                      label="Date of Birth"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      name="economicStatus"
                      label="Economic Status"
                      options={staticData.economicStatuses}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      name="motherTongue"
                      label="Mother Toungue"
                      options={staticData.motherTongues}
                      formik={formik}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        loadSelectOptions(value, "motherTongueId", "castes");
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      name="caste"
                      label="Caste"
                      options={LS.castes}
                      formik={formik}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        loadSelectOptions(value, "casteId", "subCastes");
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      name="subCaste"
                      label="Sub Caste"
                      options={LS.subCastes}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      name="country"
                      label="Country"
                      options={staticData.countries}
                      formik={formik}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        loadSelectOptions(value, "countryId", "states");
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      name="state"
                      label="State"
                      options={LS.states}
                      formik={formik}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        loadSelectOptions(value, "stateId", "districts");
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      name="district"
                      label="District"
                      options={LS.districts}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      name="qualification"
                      label="Qualification"
                      options={staticData.qualifications}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      name="profession"
                      label="Profession"
                      options={staticData.professions}
                      formik={formik}
                      onChange={(e: any) => {
                        const value = e.target.value
                        formik.setFieldValue('salaryDetails', "")
                        if (value === 5) formik.setFieldValue('salaryDetails', "0")
                        formik.setFieldValue('profession', value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      name="salaryDetails"
                      label="Monthly Income"
                      formik={formik}
                      disabled={formik?.values?.profession === 5}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <RouterLink to="/sign-in">
                      <Link component={"span"} variant="body2">
                        {"Already have an account? Sign in"}
                      </Link>
                    </RouterLink>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Formik>
        </Grid>
      </Grid>
      <DynamicModal
        open={LS.modalOpen}
        onClose={onCloseModal}
        title="OTP Verification"
        subheader="Please wait for SMS to receive OTP."
        size="xs"
        content={<SignUpOtp payload={LS.payload} />}
      />
    </Container>
  }

  return (
    <React.Fragment>
      <PublicHeader />
      <Grid container className={classes.formRow}>
        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.banner}></Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className={classes.formContainer}>
          {formContainer()}
        </Grid>
      </Grid>
      <ExclusiveFor/>
    </React.Fragment>
  );
}
