import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import { useLocalState } from "../../hooks";
import useStyles from "./style";
import Api from "../../store/api";
import { useEffect } from "react";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import ProfileImageEdit from "../profile-image";

export default function Gallery({ user, isDeletable }: any) {
  const classes = useStyles();

  const [LS, setLS] = useLocalState({ images: [], loading: false });

  const fechGallary = () => {
    setLS({ loading: true });
    Api.getGalleryImages(user.id).then((res) => {
      setLS({ images: res, loading: false });
    });
  }

  useEffect(() => fechGallary(), []);

  const handleDeleteImage = async (imageId: number) => {
    if (confirm('Are you sure to delete this image ?')) {
      setLS({ loading: true })
      const response = await Api.trashImage({ imageId, userId: user.id })
      if (response.status === 200) {
        const images = LS.images.filter((img: any) => img.id !== imageId);
        setLS({ images, loading: false })
      } else {
        alert("Error on Delete Gallary Image")
        setLS({ loading: false })
      }
    }
  }

  const handleGalaryImgUpload = async (file: Blob) => {
    setLS({ loading: true })
    const { images, status }: any = await Api.uploadGallaryImage({ file, userId: user.id })
    if (status === 200) setLS({ images, loading: false })
    else {
      setLS({ loading: false })
      alert("Error on uploading gallary image")
    }
  }

  return (
    <Grid container spacing={3} className={classes.root}>
      <Box className={classes.loader}>
        {LS.loading && <CircularProgress />}
      </Box>
      <Box className={classes.loader}>
        {!LS.loading && isDeletable && <ProfileImageEdit onUpdate={handleGalaryImgUpload} />}
      </Box>
      {LS.images.map((image: any, index: number) => (
        <Grid item key={`key-${index + 1}`} xs={12} sm={6} md={4} lg={3}>
          <Card className={classes.card}>
            <CardMedia
              className={classes.media}
              image={image.url}
              title={image.alt_description || "Image"}
            />
            {isDeletable && <IconButton
              color="secondary"
              className={classes.trash}
              onClick={() => handleDeleteImage(image.id)}
            >
              <DeleteOutline />
            </IconButton>}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
