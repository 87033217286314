import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    isMobile: false,
    platform: "",
    isAndroid: false,
    isIOS: false,
    isWindows: false,
};

const deviceSlice = createSlice({
    name: "deviceSlice",
    initialState,
    reducers: {
        setDeviceDetails: (state, action) => {
            state.isMobile = action.payload.isMobile;
            state.isAndroid = action.payload.isAndroid;
            state.isIOS = action.payload.isIOS;
            state.isWindows = action.payload.isWindows;
            state.platform = action.payload.platform;
        },
    },
});

export const deviceReducer = deviceSlice.reducer;
export const { setDeviceDetails } = deviceSlice.actions;
export default deviceSlice;