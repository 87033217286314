import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./logo.png";

const PublicHeader: React.FC = () => {
  const [isSignIn, updateIsSignIn] = useState(
    window.location.href.includes("sign-in")
  );
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };

  return (
    <>
      <AppBar position="fixed" elevation={5}>
        <Toolbar>
          <Typography
            onClick={handleBack}
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer", display: "flex"}}
          >
            <img src={Logo} height={65} />
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={() => navigate(isSignIn ? "/" : "/sign-in")}
            >
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <span style={{ fontSize: "12px" }}>
                  {isSignIn ? "Dont have account? " : "Is existing user? "}
                </span>{" "}
                {isSignIn ? "Sign Up" : "Sign In"}
              </Typography>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default PublicHeader;
