import * as Yup from 'yup';
import { SignInFormValues } from './types';

const phoneRegExp = /^[2-9][0-9]{9}$/;

const SignInValidationSchema: Yup.Schema<SignInFormValues> = Yup.object().shape({
    username: Yup.string()
        .matches(phoneRegExp, 'Invalid mobile number')
        .required('Mobile is required'),
    password: Yup.string().required('Password is required'),
});

export default SignInValidationSchema;