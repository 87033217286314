import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";

export default function PageWrapper(props:any) {
  return (
    <Box sx={{ margin: "40px 25px 100px 25px" }}>
      <Hidden mdUp>
        <Box sx={{ marginTop: "85px" }}></Box>
      </Hidden>
      {props.children}
    </Box>
  );
}
