import { TextField } from "@mui/material";
import { ErrorMessage, Field } from "formik";

export default function TextInput(props: any) {
  const { name, formik, type } = props;
  const { errors, touched, setFieldValue } = formik;
  return (
    <Field
      id={name}
      fullWidth
      size="small"
      as={TextField}
      autoComplete="off"
      value={formik.values[name] || ''}
      helperText={<ErrorMessage name={name} />}
      error={!!(errors[name] && touched[name])}
      onChange={(e: any) => {
        const value = e.target.value;
        setFieldValue(name, type === 'number' && value ? parseInt(value, 10) : value)
      }}
      {...props}
    />
  );
}
