import {
  Card,
  CardMedia,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ProfileCardSelector } from "../../store/selector";
import { useNavigate } from "react-router-dom";
import Api from "../../store/api";

const ProfileCard = ({ user, onProfileClick }: any) => {
  const navigate = useNavigate();
  const RS: any = useSelector(ProfileCardSelector);
  const { hasPayment, firstName } = RS?.authUser;
  const rows = [
    { property: "Name", value: `${user.firstName}` },
    { property: "Age", value: `${user.age} Years Old` },
    { property: "Caste", value: user.caste },
    { property: "Sub Caste", value: `${user.subCaste}` },
    { property: "Salary", value: user.salary ? `Rs. ${user.salary} per month` : '' },
    { property: "Qualification", value: user.qualification },
    { property: "Profession", value: user.profession },
    { property: "District", value: user.city },
    { property: "State", value: user.state },
    { property: "Economic Status", value: user.economicStatus },
  ];

  const handleProfileClick = (user: any) => {
    if (!hasPayment) navigate('/user/make-payment')
    else {
      Api.setViewed(user.id)
      onProfileClick(user)
    }
  }

  return (
    <Card onClick={() => handleProfileClick(user)}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <CardMedia
            width={200}
            height={400}
            component="img"
            src={user?.img}
            alt={user.name}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.property}>
                <TableCell>{row.property}</TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ProfileCard;
