
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';

const MultipleSelect = (props: any) => {
    const { name, formik, label, options } = props;
    const { errors, touched, values, handleChange } = formik;

    return (
        <FormControl variant='outlined' fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                labelId="multiple-select-label"
                id={name}
                multiple
                value={values[name]}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={touched[name] && Boolean(errors[name])}
                renderValue={(selected) =>
                    (selected as number[]).map((value) => {
                        const selectedOption = options?.find((option: any) => option.id === value);
                        return selectedOption ? selectedOption.name : '';
                    }).join(', ')
                }
                size='small'
                {...props}
            >
                {options?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                        <Checkbox checked={values[name]?.includes(option.id as never)} />
                        <ListItemText primary={option.name} />
                    </MenuItem>
                ))}
            </Select>
            {formik.touched[name] && formik.errors[name] && (
                <div style={{ color: 'red' }}>{formik.errors[name]}</div>
            )}
        </FormControl>
    );
};

export default MultipleSelect;
