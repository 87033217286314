import * as Yup from 'yup';
import { IForm } from './types';

const PasswordValidationSchema: Yup.Schema<IForm> = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), ""], 'Passwords must match')
        .required('Confirm Password is required'),
});

export default PasswordValidationSchema;