import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SelectInput from "../../inputs/select-input";
import { Form, Formik } from "formik";
import DateInput from "../../inputs/date-input";
import { ProfileSelector, getInitalValues } from "./selector";
import TextInput from "../../inputs/text-input";
import ProfileValidationSchema from "./validation";
import { IActions, IForm } from "./types";
import { useLocalState } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { staticReq } from "../../../store/slices/staticSlice";
import Api from "../../../store/api";

export default function ProfileForm() {
  // Redux State
  const RS = useSelector(ProfileSelector);
  const dispatch = useDispatch();

  // Local State
  const defaultState = {
    states: [],
    districts: [],
    nativeStates: [],
    nativeDistricts: [],
    castes: [],
    subCastes: [],
    modalOpen: true,
    initialValues: getInitalValues(RS.profile),
  };
  const [LS, updateState] = useLocalState(defaultState);

  // Life cycles
  useEffect(() => {
    dispatch(staticReq());
  }, [dispatch]);

  // Utility functions
  const loadSelectOptions = (value: number, field: string, source: string) => {
    const filter = (item: any) => item[field] === value;
    updateState({ [source]: RS[source].filter(filter) });
  };

  const handleProfileUpdate = async (values: IForm, actions: IActions) => {
    values.salaryDetails = values.monthlyIncome;
    values.religion = values.motherTongue;
    const response = await Api.updatePofileDetails(values);
    if (response.status === 200) alert('Profile updated')
    else alert("Error on profile update")
  };

  useEffect(() => {
    const { states, districts, profile, castes, subCastes } = RS;
    const { country, state, nativeCountry, nativeState, religion, caste } =
      profile;
    const nativeStates = states.filter((s: any) => s.countryId === nativeCountry);
    const nativeDistricts = districts.filter((d: any) => d.stateId === nativeState)
    const newState = {
      states: states.filter((s: any) => s.countryId === country),
      districts: districts.filter((d: any) => d.stateId === state),
      nativeStates: nativeStates.length ? nativeStates : [{}],
      nativeDistricts: nativeDistricts.length ? nativeDistricts : [{}],
      castes: castes.filter((c: any) => c.motherTongueId === religion),
      subCastes: subCastes.filter((c: any) => c.casteId === caste),
    };
    updateState(newState);
  }, [RS]);

  return (
    <>
      {RS.profile && LS.initialValues ? (
        <Formik
          // validationSchema={ProfileValidationSchema}
          initialValues={LS.initialValues}
          onSubmit={handleProfileUpdate}
        >
          {(formik: any) => (
            <Box component={Form} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextInput
                    name="aboutYou"
                    label="About You"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="firstName"
                    label="First Name"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    label="Last Name"
                    name="lastName"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="gender"
                    label="Gender"
                    options={RS.genders}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DateInput
                    name="dateOfBirth"
                    label="Date of Birth"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="onBehalf"
                    label="Create Profile For"
                    formik={formik}
                    options={RS.behalfs}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="property"
                    label="Property details"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="economicStatus"
                    label="Economic Status"
                    options={RS.economicStatuses}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="motherTongue"
                    label="Mother Toungue"
                    options={RS.motherTongues}
                    formik={formik}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      loadSelectOptions(value, "motherTongueId", "castes");
                      formik.handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="caste"
                    label="Caste"
                    options={LS.castes}
                    formik={formik}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      loadSelectOptions(value, "casteId", "subCastes");
                      formik.handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="subCaste"
                    label="Sub Caste"
                    options={LS.subCastes}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="subCasteAdditional"
                    label="Sub Caste Additional Details"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="country"
                    label="Country"
                    options={RS.countries}
                    formik={formik}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      loadSelectOptions(value, "countryId", "states");
                      formik.handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="state"
                    label="State"
                    options={LS.states}
                    formik={formik}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      loadSelectOptions(value, "stateId", "districts");
                      formik.handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="district"
                    label="District"
                    options={LS.districts}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="address"
                    label="Full Address"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="nativeCountry"
                    label="Native Country"
                    options={RS.countries}
                    formik={formik}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      loadSelectOptions(value, "countryId", "nativeStates");
                      formik.handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="nativeState"
                    label="Native State"
                    options={LS.nativeStates}
                    formik={formik}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      loadSelectOptions(value, "stateId", "nativeDistricts");
                      formik.handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="nativeDistrict"
                    label="Native District"
                    options={LS.nativeDistricts}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="nativeAddress"
                    label="Native Full Address"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="qualification"
                    label="Qualification"
                    options={RS.qualifications}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="profession"
                    label="Profession"
                    options={RS.professions}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="monthlyIncome"
                    label="Monthly Income"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="maritalStatus"
                    label="Marital Status"
                    formik={formik}
                    options={RS.maritialStatuses}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput name="height" label="Height (In inches) " formik={formik} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput name="weight" label="Weight (kg's)" formik={formik} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="physicalStatus"
                    label="Physical Status "
                    formik={formik}
                    options={[
                      { id: "general", name: "General" },
                      { id: "Different Talented", name: "Different Talented" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="bodyType"
                    label="Body Type"
                    formik={formik}
                    options={[
                      { id: "Slim", name: "Slim" },
                      { id: "Average", name: "Average" },
                      { id: "Athletic", name: "Athletic" },
                      { id: "Heavy", name: "Heavy" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="alt1Contact"
                    label="Contact 1 "
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="alt2Contact"
                    label="Contact 2 "
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="familyType"
                    label="Food Habit"
                    formik={formik}
                    options={[
                      { id: 1, name: "Join Family" },
                      { id: 2, name: "Nuclear Family" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="fatherName"
                    label="Father Name"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="fatherOccupation"
                    label="Father`s Occupation "
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="motherName"
                    label="Mother Name  "
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="motherOccupation"
                    label="Mother`s Occupation"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="noOfSisters"
                    label="No Of Sisters"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="noOfSistersMarried"
                    label="No Of Sisters Married "
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="noOfBrothers"
                    label="No Of Brothers"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="noOfBrothersMarried"
                    label="No Of Brothers Married"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="birthOrder"
                    label="Birth Order"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="sevvai"
                    label="Sevvai"
                    formik={formik}
                    options={[
                      { id: "yes", name: "yes" },
                      { id: "no", name: "no" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="raghuKedhu"
                    label="Raghu Kedhu"
                    formik={formik}
                    options={[
                      { id: "yes", name: "yes" },
                      { id: "no", name: "no" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="birthTime"
                    label="Birth Time"
                    formik={formik}
                    type="time"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    name="birthPlace"
                    label="Birth Place"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="star"
                    label="Star"
                    formik={formik}
                    options={RS.stars}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="raasi"
                    label="Raasi"
                    formik={formik}
                    options={RS.raasis}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="lagnam"
                    label="Lagnam"
                    formik={formik}
                    options={RS.lagnams}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectInput
                    name="paadham"
                    label="Paadham"
                    formik={formik}
                    options={RS.paadhams}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Update
              </Button>
            </Box>
          )}
        </Formik>
      ) : (
        "Loading..."
      )}
    </>
  );
}
