import { Box, Button, Container, Grid, Link } from "@mui/material";
import { Form, Formik } from "formik";
import TextInput from "../inputs/text-input";
import { useEffect } from "react";
import { useLocalState } from "../../hooks";
import {
  MobileFormValidationSchema,
  OTPFormValidationSchema,
} from "./validation";
import Api from "../../store/api";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/slices/authSlice";

export default function SignInOtp() {
  const dispatch = useDispatch();
  // Local State
  const [LS, setState] = useLocalState({
    countDown: 60,
    otpSent: false,
    isLoading: false,
    loaderMessage: "",
    mobile: null,
    response: null,
  });

  // life cycles

  useEffect(() => {
    const res = LS.response;
    if (res?.data?.error)
      dispatch(authActions.signInErr({ message: res.data.message }));
    else if (res?.data) {
      const { accessToken } = res.data;
      localStorage.setItem("accessToken", accessToken);
      dispatch(authActions.signInRes(res.data));
    }
  }, [LS.response, dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (LS.countDown > 0) setState({ countDown: LS.countDown - 1 });
    }, 1000);
    return () => clearTimeout(timer);
  });

  const onResendClick = () => {
    if (LS.countDown) return;
    setState({ countDown: 60 });
  };

  const handleMobileFormSubmit = (values: any, actions: any) => {
    setState({ isLoading: true, loaderMessage: "Sending..." });
    Api.sendSignInOTP(values)
      .then((res: any) => {
        setState({ isLoading: false, otpSent: true, ...values });
      })
      .catch((error: any) => {
        setState({ isLoading: false, loaderMessage: null });
      });
  };

  const handleOTPFormSubit = (values: any, actions: any) => {
    setState({ isLoading: true, loaderMessage: "Veryfing..." });
    Api.verifySignInOTP({ mobile: LS.mobile, ...values })
      .then((res: any) => {
        setState({ isLoading: false, loaderMessage: null, response: res });
      })
      .catch((error: any) => {
        setState({ isLoading: false, loaderMessage: null });
      });
  };

  return (
    <Container maxWidth="xl">
      {LS.isLoading ? (
        LS.loaderMessage
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {LS.otpSent ? (
            <Formik
              validationSchema={OTPFormValidationSchema}
              initialValues={{ otp: "" }}
              onSubmit={handleOTPFormSubit}
            >
              {(formik: any) => (
                <Box component={Form} sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                      <TextInput
                        name="otp"
                        label="One Time Password"
                        formik={formik}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Verify OTP
                  </Button>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link component={"span"} variant="body2">
                        If not received?{" "}
                        <Button
                          sx={{
                            color: LS.countDown ? "gray" : "green",
                            cursor: "pointer",
                          }}
                          onClick={onResendClick}
                        >
                          <b>RESEND</b>
                        </Button>{" "}
                        after{" "}
                        <span style={{ color: "green" }}>
                          <b>
                            {LS.countDown < 10 ? "0" : ""}
                            {LS.countDown}
                          </b>
                        </span>{" "}
                        seconds
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Formik>
          ) : (
            <Formik
              validationSchema={MobileFormValidationSchema}
              initialValues={{ mobile: "" }}
              onSubmit={handleMobileFormSubmit}
            >
              {(formik: any) => (
                <Box component={Form} sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                      <TextInput
                        autoFocus
                        name="mobile"
                        label="Mobile Number"
                        formik={formik}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Send OTP
                  </Button>
                </Box>
              )}
            </Formik>
          )}
        </Box>
      )}
    </Container>
  );
}
