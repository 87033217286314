import { AppBar, Toolbar, Typography, Button, Link } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useLocalState } from "../../hooks";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch } from "react-redux";
import { profileReq } from "../../store/slices/profileSlice";

  const payload = { limit: 12, page: 1, isInit: true, listName: "all" };
  const SimpleHeader: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  
  const [LS, setState] = useLocalState({ title: '' });
  const handleBack = () => {
    if (['Recent Views', 'Edit Profile'].includes(LS.title)) {
      dispatch(profileReq(payload));
    }
    navigate(-1)
  }

  useEffect(() => {
    if (location.pathname.includes('edit-profile')) setState({ title: 'Edit Profile' })
    if (location.pathname.includes('view-profile')) setState({ title: 'Profile Details' })
    if (location.pathname.includes('make-payment')) setState({ title: 'Payment' })
    if (location.pathname.includes('recent-views')) setState({ title: 'Recent Views' })
    if (location.pathname.includes('settings')) setState({ title: 'Settings' })
  }, [location.pathname])

  return (
    <>
      <AppBar position="fixed" elevation={5}>
        <Toolbar>
          <Link onClick={handleBack} sx={{ color: 'white', cursor: 'pointer', mr: 2 }}>
            <ArrowBackIcon sx={{ marginTop: '5px' }} />
          </Link>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer" }}
          >
            {LS?.title}
          </Typography>

        </Toolbar>
      </AppBar>
    </>
  );
};

export default SimpleHeader;
