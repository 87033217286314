import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ErrorMessage } from "formik";

export default function DateInput(props: any) {
  const { name, formik, label } = props;
  const { errors, touched, values } = formik;

  const formatDate = (date: Date) => {
    if(!date) return date;
    try {
      const newDate = new Date(date);
      newDate.setDate(new Date(date).getDate() + 1)
      const isoDate = newDate.toISOString();
      return isoDate.split('T')[0];
    } catch (error) {
      return null
    }
  };

  return (
    <DatePicker
      inputFormat="DD-MM-YYYY"
      label={label}
      value={values[name]}
      onChange={(date) => formik.setFieldValue(name, formatDate(date))}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          error={!!(errors[name] && touched[name])}
          helperText={<ErrorMessage name={name} />}
        />
      )}
    />
  );
}
