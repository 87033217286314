import { Card, CardContent, CardHeader, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme: Theme) => {
    const { mode, grey } = theme.palette;
    return {
        header: {
            backgroundColor: mode === "light" ? grey[200] : '#242424',
        }
    }
})
export default function CardWrapper(props: any) {
    const { title, children } = props;
    const classes = useStyle()
    return (
        <Card variant='outlined' sx={{marginBottom:'20px'}}>
            <CardHeader className={classes.header} title={title} />
            <CardContent>
                {children}
            </CardContent>
        </Card>
    )
}
