import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from '../../../components/page-wrapper'
import { profileReq } from '../../../store/slices/profileSlice';
import { useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import ProfileCard from '../../../components/profile-card';
import { useNavigate } from 'react-router-dom';
import Api from '../../../store/api';

const payload = { limit: 12, page: 1, isInit: true, listName: "recent" };
export default function RecentViews() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const RS = useSelector((state: any) => state.profiles)

  useEffect(() => {
    dispatch(profileReq(payload));
  }, [])

  const paginate = (limit: number, page: number) => {
    dispatch(profileReq({ limit, page, listName: RS.listName }));
  };

  // eslint-disable-next-line
  const handleScroll = useCallback(
    Api.debounce(() => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 100 && !RS.isLoading) {
        const totalPages = Math.ceil(RS.totalItems / 12);
        if (RS.currentPage < totalPages) paginate(12, RS.currentPage + 1);
      }
    }, 200),
    [RS.isLoading, RS.currentPage, paginate]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const onProfileClick = async (user: any) => {
    const result: any = await Api.compressImage(user.img, 300, 300)
    if (!result?.error) {
      const newUser = { ...user, img: result.imageUri }
      sessionStorage.setItem('selectedProfile', JSON.stringify(newUser));
      navigate('/user/view-profile')
    } else { alert("try again later") }
  };

  return (
    <PageWrapper>
      <Grid container spacing={3}>
        {RS.list.map((user: any, index: number) => {
          return (
            <Grid key={`key-${index + 1}`} item xs={12} sm={6} md={4} lg={3}>
              <ProfileCard onProfileClick={onProfileClick} user={user} />
            </Grid>
          );
        })}
      </Grid>
    </PageWrapper>
  )
}
