import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme: Theme) => {

    return {
        formRow: {
            background: '#baf9dc',
            paddingTop: '80px',
            paddingBottom: '80px',
        },
        communityRowContainer: {
            background: '#baf9dc',
        },
        communityRow: {
            background: 'white',
            borderTopLeftRadius: '150px',
            padding: '50px',
        },
        mdUpBox: { marginTop: 13 },
        banner: {
            backgroundImage: `url(https://tnyadavs.com/assets/img/home-two/banners/new_green.png);`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '80% 100%',
            backgroundPosition: 'center center',
            minHeight: '200px'
        },
        formContainer: {
            background: 'white',
            borderTopLeftRadius: '100px',
            borderBottomLeftRadius: '100px',
            padding: '50px 0px 50px 0px'
        },
        subTitle: {
            display: 'inline-block',
            position: 'relative',
            paddingLeft: '10px',
            zIndex: 1,
            textAlign: 'left',
            '&:before': {
                position: 'absolute',
                content: '""',
                width: '20px',
                height: '25px',
                left: '0',
                top: '-2px',
                backgroundColor: '#fe4a55',
                borderRadius: '50%',
                zIndex: -1,
            },
        },
    }
})