import * as Yup from 'yup';
import { ISignUpForm } from './types';

const phoneRegExp = /^[2-9][0-9]{9}$/;
const incomeRegExp = /^[0-9]+$/;

const SignUpValidationSchema: Yup.Schema<ISignUpForm> = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    gender: Yup.string().required('Gender is required'),
    dateOfBirth: Yup.string().required('Date of birth is required'),
    username: Yup.string().matches(phoneRegExp, 'Invalid phone number').required('Phone number is required'),
    motherTongue: Yup.string().required('Mother tongue is required'),
    caste: Yup.string().required('Caste is required'),
    subCaste: Yup.string().required('Sub caste is required'),
    qualification: Yup.string().required('Qualification is required'),
    profession: Yup.string().required('Profession is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    district: Yup.string().required('District is required'),
    // salaryDetails: Yup.string().matches(incomeRegExp, 'Invalid income').required('Monthly income is required'),
    salaryDetails: Yup.string().when('profession', {
        is: (profession:string) =>  profession !== '5',
        then: (schema) => schema.matches(incomeRegExp, 'Invalid income').required(),
        otherwise: (Schema) => Schema.optional()
      }),
    economicStatus: Yup.string().required('Economic status is required'),
});

export default SignUpValidationSchema;