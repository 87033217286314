import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
    tabList: {
        '& .MuiTabs-flexContainer': {
            gap: '0px',
            padding: '0px !important',
        },
        '& .MuiTab-root': {
            fontSize: '12px',
        }
    },
    appBar: {
        zIndex: "200",
        position: "absolute"
    },
    blank: {
        height: "250px",
        width: "100%",
    },
    action: {
        position: "fixed",
        bottom: 26,
        right: 26,
        zIndex: 3,
    },
    actionButton: {
        width: 60,
        height: 60,
        backgroundColor: theme.palette.primary.main,
    },
    actionIcon: {
        fontSize: 40,
        color: "#fff",
    },
    paper: {
        paddingTop: "30px",
        borderRadius: "0",
        zIndex: "100"
    },
    root: {
        marginTop: theme.spacing(2),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    media: {
        paddingTop: '56.25%',
        height: 400,
        zIndex: 1
    },
    trash: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        zIndex: 2,
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    loader: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        width: '100%'
    }
}));

export default useStyles;