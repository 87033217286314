import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export default function Contact({ user }: any) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Contact Details</Typography>
        <Divider />
        <Typography color="textSecondary">{`Alternative Contact 1: ${user.alt1Contact}`}</Typography>
        <Typography color="textSecondary">{`Alternative Contact 2: ${user.alt2Contact}`}</Typography>
      </CardContent>
    </Card>
  );
}
