import SendIcon from "@mui/icons-material/Send";
import ReportIcon from "@mui/icons-material/Report";
import BlockIcon from "@mui/icons-material/Block";
import useStyles from "./style";
import { useDispatch } from "react-redux";
import { profileActions } from "../../store/slices/profileSlice";


import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import React from "react";
import ConfirmationDialog from "../confirm-dialog";
import { useLocalState } from "../../hooks";

const actions = [
  { icon: <SendIcon />, name: 'Send Interest' },
  { icon: <SaveIcon />, name: 'Add In MyBook' },
  { icon: <BlockIcon />, name: 'Block This User' },
  { icon: <ShareIcon />, name: 'Share This User' },
];

export default function Actions() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [LS, setLS] = useLocalState({ dialogOpen: false, actionName: '', message: '' })

  const handleAction = (actionName: string) => {
    const message = `Are You Sure To ${actionName}?`
    setLS({ actionName, dialogOpen: true, message })
  }

  const onConfrim = () => {
    const selectedProfile = sessionStorage.getItem('selectedProfile');
    if (selectedProfile && LS.actionName === 'Send Interest') {
      const user = JSON.parse(selectedProfile);
      if (user.isInterestSent) return alert('Already Interest Sent');
      dispatch(profileActions.sendIntReq(user.id));
    }
  }

  return (
    <React.Fragment>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        className={classes.action}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleAction(action.name)}
          />
        ))}
      </SpeedDial>
      <ConfirmationDialog
        open={LS.dialogOpen}
        onConfirm={onConfrim}
        onClose={() => setLS({ dialogOpen: false })}
        message={LS.message}
        title={LS.actionName.toUpperCase()}
      />
    </React.Fragment>
  )
}
