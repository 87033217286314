
import * as Yup from "yup";

export const MobileFormValidationSchema = Yup.object().shape({
    mobile: Yup.string()
        .length(10, "OTP must be 10 digits")
        .matches(/^[0-9]+$/, "Must be only digits")
        .required("Mobile Number Required")
});
export const OTPFormValidationSchema = Yup.object().shape({
    otp: Yup.string()
        .length(6, "OTP must be 6 digits")
        .matches(/^[0-9]+$/, "Must be only digits")
        .required("Required"),
});
