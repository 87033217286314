import { createSelector } from "@reduxjs/toolkit";

export const getInitalValues = (profile: any) => {
    return {
        ...profile,
        motherTongue: profile.religion,
        monthlyIncome: profile.salaryDetails,
    }
}

export const ProfileSelector = createSelector(
    (state: any) => state.staticData,
    (state: any) => state.auth.user,
    (staticData: any, user) => {
        return {
            ...staticData,
            nativeStates: staticData.states,
            nativeDistricts: staticData.districts,
            profile: user.profile
        }
    }
);