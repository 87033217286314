import { Card, CardHeader, CardContent, Container, Modal } from "@mui/material";
import useStyles from "./style";

interface IDynamicModal {
  open: boolean;
  onClose: (event: MouseEvent, resion: string) => void;
  title: string;
  subheader: string;
  content: JSX.Element;
  size: "xs" | "sm" | "md" | "lg" | "xl";
}

function DynamicModal(props: Readonly<IDynamicModal>) {
  const { open, onClose, title, subheader, content, size } = props;
  const classes = useStyles();
  return (
    <Container maxWidth={size}>
      <Modal
        open={open}
        onClose={onClose}
        className={classes.modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card>
          <CardHeader
            title={title}
            subheader={subheader}
            className={classes.cardHeader}
          >
            {title}
          </CardHeader>
          <CardContent>{content}</CardContent>
        </Card>
      </Modal>
    </Container>
  );
}

export default DynamicModal;
