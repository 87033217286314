import { createSelector } from "@reduxjs/toolkit"
import { DeviceSelector } from "../device/selector"
import { selectAuthUser } from "../../pages/sign-in/selector"

function getExpiryDays(paymentExpiryDate:string) {
    if(!paymentExpiryDate) return 0;
    const expiryDate:any = new Date(paymentExpiryDate);
    const currentDate:any = new Date();
    const differenceInMs = expiryDate - currentDate;
    const daysUntilExpiry = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    return daysUntilExpiry;
}
  

export const UserProfileMenuSelector = createSelector(
    [selectAuthUser, DeviceSelector],
    (user, device) => {
        const expiryIn = getExpiryDays(user.paymentExpiryDate);
        const updatedAuthUser = { ...user, expiryIn, img: user?.profile?.img }
        return { authUser: updatedAuthUser, device }
    }
)