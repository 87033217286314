import { Avatar, Box, Card, Grid, Typography } from '@mui/material'
import React from 'react'
import Img from '../../assets/images/krishna.png'
import App1 from '../../assets/images/app1.png'
import App2 from '../../assets/images/app2.png'
import { useStyle } from "../../pages/sign-in/style";


export default function ExclusiveFor() {
    const classes = useStyle();
    let caste = ['Tamil Yadav', 'Golla(Yadav) Naidu', 'Anuppa Gounder (Krishna Golla)', 'Asthanthra Golla Nayakar', 'Rajakambala Golla Nayakar', 'Mod,Pokkisa,Tumati,Puja', 'Krishna Vaka', 'Kuruba Goundar']
    return (
        <React.Fragment>
            <Box className={classes.communityRowContainer}>
                <Grid
                    container className={classes.communityRow}>
                    <Grid item xs={12} container alignItems="center" justifyContent="center">
                        <Typography color='#221668' component="span" className={classes.subTitle}>
                            Sree Krishna
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '50px' }}>
                        <Typography color='#221668' component="h1" variant="h4" textAlign="center">
                            This Site is Exclusive For
                        </Typography>
                    </Grid>

                    {caste.map((item, index) => (
                        <Grid  key={index} item xs={12} sm={12} md={6} lg={4} xl={3} sx={{ p: 1 }}>
                            <Card sx={{ border: '1px solid red' }}>
                                <Grid container sx={{ p: 4 }}>
                                    <Grid item>
                                        <Avatar alt="Avatar" src={Img} />
                                    </Grid>
                                    <Grid item sx={{ pl: 4 }}>
                                        <Typography component='h4' color="#fe4a55" textAlign="left">
                                            {item}
                                        </Typography>
                                        <Typography color="#5f5656" textAlign="left" component='h6'> Get Verified Profiles</Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Box>
            <Grid container sx={{ marginBottom: '0px' }}>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Typography color='#221668' component="span" className={classes.subTitle}>
                        Looking for a life partner?
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                    <Typography color='#221668' component="h1" variant="h4" textAlign="center">
                        Download the TnYadavs app
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{ p: 5 }}>
                        <Card elevation={0} sx={{ textAlign: 'right' }}>
                            <img src={App1} />
                        </Card>
                    </Grid>
                    <Grid item xs={6} sx={{ p: 5 }}>
                        <Card elevation={0}>
                            <img src={App2} />
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
