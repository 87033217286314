// SettingsPage.tsx
import React, { useEffect, useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField,
  IconButton,
  AppBar,
  Toolbar,
  SwipeableDrawer,
  Box,
  Divider,
  Button,
  Hidden,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PageWrapper from '../../../components/page-wrapper';
import { ArrowForwardIos } from '@mui/icons-material';
import Password from '../../../components/forms/password';
import { useDispatch, useSelector } from 'react-redux';
import { themeReq } from '../../../store/slices/authSlice';

interface Setting {
  label: string;
  subSettings?: boolean;
}

const settingsList: Setting[] = [
  {
    label: 'Dark Theme',
    subSettings: false
  },
  {
    label: 'Change Password',
    subSettings: true,
  },
  {
    label: 'Blocked Profiles',
    subSettings: true,
  },
];

const SettingsPage: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state: any) => state.auth.theme);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState<Setting | null>(null);
  const [darkTheme, setDarkTheme] = useState(theme === 'dark');

  const handleSettingClick = (setting: Setting) => {
    if (!setting.subSettings) return;
    setSelectedSetting(setting);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setSelectedSetting(null);
    setDrawerOpen(false);
  };

  const handleThemeToggle = () => {
    const darkMode = theme !== "dark";
    localStorage.setItem("theme", darkMode ? "dark" : "light");
    dispatch(themeReq(darkMode ? "dark" : "light"));
    setDarkTheme(!darkTheme);
  };

  return (
    <>
      <Hidden mdUp>
        <Box sx={{ marginTop: "50px" }}></Box>
      </Hidden>
      <List>
        <Divider />
        {settingsList.map((setting, index) => (
          <React.Fragment key={index}>
            <ListItem onClick={() => handleSettingClick(setting)}>
              <ListItemText primary={setting.label} />
              {setting.label === 'Dark Theme' && <Switch checked={darkTheme} onChange={handleThemeToggle} />}
              {setting.subSettings && <ArrowForwardIos />}
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        onOpen={() => { }}
        PaperProps={{ style: { width: '100%' } }}
      >
        <Box>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleDrawerClose}
                sx={{ mr: 2 }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" component="div">
                {selectedSetting?.label}
              </Typography>
            </Toolbar>
          </AppBar>
          {selectedSetting?.label === 'Change Password' && (
            <Password />
          )}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default SettingsPage;
