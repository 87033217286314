import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    genders: [
        { id: "MALE", name: "MALE" },
        { id: "FEMALE", name: "FEMALE" },
    ],
    economicStatuses: [],
    qualifications: [],
    professions: [],
    motherTongues: [],
    castes: [],
    subCastes: [],
    countries: [],
    states: [],
    districts: [],
    isLoading: false,
};

const staticSlice = createSlice({
    name: 'static',
    initialState,
    reducers: {
        staticReq: (state) => {
            state.isLoading = true;
        },
        staticRes: (state, action) => {
            if (action?.payload?.error) {
                state.isLoading = false;
                return alert(action.payload.message)
            }
            for (const key in action.payload) {
                state[key] = action.payload[key];
            }
            state.isLoading = false;
        },
        staticErr: (state, action) => {
            for (const key in initialState)
                state[key] = initialState[key];
            alert(action.payload.message)
        }
    },
});

export const { staticReq, staticRes, staticErr } = staticSlice.actions;
export const staticReducer = staticSlice.reducer;
export const staticActions = staticSlice.actions;
export default staticSlice;
