import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Field, Form, Formik } from "formik";
import { SearchSelector, getInitalValues } from "./selector";
import TextInput from "../../inputs/text-input";
import SearchValidationSchema from "./validation";
import { IActions, IForm } from "./types";
import { useLocalState } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { staticReq } from "../../../store/slices/staticSlice";
import MultipleSelect from "../../inputs/multi-select";
import { Card, Checkbox, FormControlLabel } from "@mui/material";

export default function Search({ onSearch }: any) {
  // Redux State
  const RS = useSelector(SearchSelector);
  const dispatch = useDispatch();

  // Local State
  const defaultState = {
    states: [],
    districts: [],
    nativeStates: [],
    nativeDistricts: [],
    castes: [],
    subCastes: [],
    modalOpen: true,
    initialValues: getInitalValues(),
  };
  const [LS, updateState] = useLocalState(defaultState);

  // Life cycles
  useEffect(() => {
    dispatch(staticReq());
  }, [dispatch]);

  // Utility functions
  const loadSelectOptions = (
    value: number[],
    field: string,
    source: string
  ) => {
    const filter = (item: any) => value.includes(item[field]);
    updateState({ [source]: RS[source].filter(filter) });
  };

  const handleSearchUpdate = async (values: IForm, actions: IActions) => {
    onSearch(values);
  };

  useEffect(() => {
    const { states, districts, search, castes, subCastes } = RS;
    const { country, state, nativeCountry, nativeState, religion, caste } =
      search;
    // const newState = {
    //   states: states.filter((s: any) => s.countryId === country),
    //   districts: districts.filter((d: any) => d.stateId === state),
    //   nativeStates: states.filter((s: any) => s.countryId === nativeCountry),
    //   nativeDistricts: districts.filter((d: any) => d.stateId === nativeState),
    //   castes: castes.filter((c: any) => c.motherTongueId === religion),
    //   subCastes: subCastes.filter((c: any) => c.casteId === caste),
    // };
    // updateState(newState);
  }, [RS]);

  return (
    <>
      {RS.search && LS.initialValues ? (
        <Formik
          validationSchema={SearchValidationSchema}
          initialValues={LS.initialValues}
          onSubmit={handleSearchUpdate}
        >
          {(formik: any) => (
            <Card sx={{ p: 3 }}>
              <Box component={Form} sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      name="memberId"
                      label="Member Id"
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="maritalStatus"
                      label="Marital Status"
                      formik={formik}
                      options={RS.maritialStatuses}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      name="ageFrom"
                      label="Age From"
                      formik={formik}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      name="ageTo"
                      label="Age To"
                      formik={formik}
                      type="number"
                    />
                  </Grid>
                </Grid>

                <hr />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="countries"
                      label="Current Country"
                      options={RS.countries}
                      formik={formik}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        loadSelectOptions(value, "countryId", "states");
                        formik.setFieldValue("states", []);
                        formik.setFieldValue("cities", []);
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="states"
                      label="Current State"
                      options={LS.states}
                      formik={formik}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        loadSelectOptions(value, "stateId", "districts");
                        formik.setFieldValue("cities", []);
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="cities"
                      label="Current District/City"
                      options={LS.districts}
                      formik={formik}
                    />
                  </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="nativeCountries"
                      label="Native Country"
                      options={RS.countries}
                      formik={formik}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        loadSelectOptions(value, "countryId", "nativeStates");
                        formik.setFieldValue("nativeStates", []);
                        formik.setFieldValue("nativeCities", []);
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="nativeStates"
                      label="Native State"
                      options={LS.nativeStates}
                      formik={formik}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        loadSelectOptions(value, "stateId", "nativeDistricts");
                        formik.setFieldValue("nativeCities", []);
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="nativeCities"
                      label="Native District/City"
                      options={LS.nativeDistricts}
                      formik={formik}
                    />
                  </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="religions"
                      label="Mother Toungue"
                      options={RS.motherTongues}
                      formik={formik}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        loadSelectOptions(value, "motherTongueId", "castes");
                        formik.setFieldValue("castes", []);
                        formik.setFieldValue("subCastes", []);
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="castes"
                      label="Caste"
                      options={LS.castes}
                      formik={formik}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        loadSelectOptions(value, "casteId", "subCastes");
                        formik.setFieldValue("subCastes", []);
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="subCastes"
                      label="Sub Caste"
                      options={LS.subCastes}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="economicStatuses"
                      label="Economic Status"
                      options={RS.economicStatuses}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="qualifications"
                      label="Qualification"
                      options={RS.qualifications}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="professions"
                      label="Profession"
                      options={RS.professions}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="stars"
                      label="Star"
                      formik={formik}
                      options={RS.stars}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="sevvaiDhosam">
                      {({ field }: any) => (
                        <FormControlLabel
                          control={<Checkbox {...field} />}
                          label="Sevvai"
                          checked={field.value}
                        />
                      )}
                    </Field>
                    <Field name="raghuKethuDhosam">
                      {({ field }: any) => (
                        <FormControlLabel
                          control={<Checkbox {...field} />}
                          label="Raghu Kethu"
                          checked={field.value}
                        />
                      )}
                    </Field>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="dhosam"
                      label="Dhosam"
                      formik={formik}
                      options={[
                        {
                          id: "Raghu/Kedhu Dhosam ",
                          name: "Raghu/Kedhu Dhosam",
                        },
                        { id: "Sevvai Dhosam", name: "Sevvai Dhosam" },
                        { id: "None", name: "None" },
                      ]}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <MultipleSelect
                      name="physicalStatus"
                      label="Physical Status "
                      formik={formik}
                      options={[
                        { id: "general", name: "General" },
                        {
                          id: "Different Talented",
                          name: "Different Talented",
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Search
                </Button>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => {
                    sessionStorage.removeItem("preference");
                    formik.resetForm();
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Card>
          )}
        </Formik>
      ) : (
        "Loading..."
      )}
    </>
  );
}
