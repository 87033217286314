import { Form, Formik } from "formik";
import PasswordValidationSchema from "./validation";
import TextInput from "../../inputs/text-input";
import { Box, Button, Card, Grid } from "@mui/material";
import { IForm } from "./types";
import Api from "../../../store/api";
import { useSelector } from "react-redux";
import { selectAuthUser } from "../../../pages/sign-in/selector";

export default function Password() {
    const authUser = useSelector(selectAuthUser)
    const handleSubmit = async (values: IForm, action: any) => {
        const response = await Api.changePassword({ newPassword: values.password, userId: authUser.id })
        if (response.data.error === false) { 
            alert(response.data.message);
            action.resetForm()
        }
        else alert(response.data.message)

    }
    return (
        <Card sx={{ margin: '10px', padding: '10px', mt:2 }}>
            <Formik
                initialValues={{ password: '', confirm_password: '' }}
                validationSchema={PasswordValidationSchema}
                onSubmit={handleSubmit}>
                {(formik: any) => (
                    <Box component={Form} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    name="password"
                                    label="Password"
                                    formik={formik}
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    name="confirm_password"
                                    label="Confirm Password"
                                    formik={formik}
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mb: 2 }}>
                                    UPDATE
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Formik>
        </Card>
    )
}
