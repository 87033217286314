import { takeEvery, put } from 'redux-saga/effects';
import { authActions } from '../slices/authSlice';
import Api from '../api';

type LoginAction = ReturnType<typeof authActions.signReq>;
type UpdateDPAction = ReturnType<typeof authActions.updateDPReq>;

function* singIn(action: LoginAction): Generator {
    try {
        const response: any = yield Api.signIn(action.payload);
        yield put(authActions.signInRes(response.data));
    } catch (error: any) {
        yield put(authActions.signInErr({ message: error.message }));
    }
}

function* validateToken(): Generator {
    try {
        const response: any = yield Api.validateToken();
        yield put(authActions.verifyRes(response.data));
    } catch (error: any) {
        if (error.response.status === 401) {
            localStorage.removeItem('accessToken');
            yield put(authActions.verifyErr({ message: "Session time out" }));
        }
    }
}

function* updateDP(action: UpdateDPAction): Generator {
    try {
        const { status, img }: any = yield Api.updateProfileImage(action.payload);
        if (status === 200) yield put(authActions.updateDPRes(img));
        else yield put(authActions.updateDPErr({ message: "Error on updating DP" }));
    } catch (error: any) {
        if (error.response.status === 401)
            yield put(authActions.updateDPErr({ message: "Session time out" }));
        yield put(authActions.updateDPErr({ message: "Error on updating DP" }));
    }
}

function* authSaga() {
    yield takeEvery(authActions.signReq.type, singIn);
    yield takeEvery(authActions.verifyReq.type, validateToken);
    yield takeEvery(authActions.updateDPReq.type, updateDP);
}

export default authSaga;